import {
  Heart as HeartOutline,
  ChevronBack,
  ChevronForward,
  Trash,
  Chatbox,
  Mail as MailOutline,
  Star as StarOutline,
  Notifications as NotificationsOutline,
  Settings,
  Search,
  Move,
  HelpCircle,
  AlertCircle,
  Create,
  CloseCircle,
  Link,
  Location,
  AddCircle,
  Play,
  Pause,
} from '@styled-icons/ionicons-outline'
import {
  Heart,
  Mail,
  Checkmark,
  Close,
  Star,
  EllipsisVertical,
  Notifications,
  LogoVimeo,
  LogoYoutube,
  LogoInstagram,
  LogoBehance,
  LogoDribbble,
  LogoFacebook,
  LogoTwitter,
  LogoLinkedin,
  LogoWhatsapp,
  Person,
  People,
} from '@styled-icons/ionicons-solid'
import styled, { css } from 'styled-components'

const Icon = () => null

Icon.Search = Search
Icon.AddCircle = AddCircle
Icon.Play = Play
Icon.Pause = Pause

const withPointer = comp =>
  styled(styled(comp)`
    cursor: pointer;
  `)

const defaultStyled = (comp, size = 28, color) =>
  styled(styled(comp).attrs(props => ({ size, ...props }))`
    ${({ theme: { colors }, pointer = true }) => css`
      cursor: ${pointer && 'pointer'};
      color: ${colors[color]};
    `}
  `)

const defaultSocialLinkStyled = (comp, size = 22) =>
  styled(styled(comp).attrs(props => ({ size, ...props }))`
    ${({ theme: { colors } }) => css`
      cursor: pointer;
      color: ${colors.socialIcon};
      transition: 0.4s;

      &:hover {
        color: ${colors.socialIconHover};
      }
    `}
  `)

// Icon.HeartOutline = props => <HeartOutline size={28} {...props} />
Icon.HeartOutline = defaultStyled(HeartOutline)`
`
Icon.Location = defaultStyled(Location, 17)``

Icon.Links = {
  Vimeo: defaultSocialLinkStyled(LogoVimeo)``,
  Youtube: defaultSocialLinkStyled(LogoYoutube)``,
  Instagram: defaultSocialLinkStyled(LogoInstagram)``,
  Behance: defaultSocialLinkStyled(LogoBehance)``,
  Dribbble: defaultSocialLinkStyled(LogoDribbble)``,
  Facebook: defaultSocialLinkStyled(LogoFacebook)``,
  Linkedin: defaultSocialLinkStyled(LogoLinkedin)``,
  Twitter: defaultSocialLinkStyled(LogoTwitter)``,
  Web: defaultSocialLinkStyled(Link)``,
}

Icon.Menu = {
  Heart: defaultStyled(HeartOutline, 26)`
  `,
  HeartActive: defaultStyled(Heart, 26, 'primary')`
  `,
  Messages: defaultStyled(MailOutline, 26)`
  `,
  MessagesActive: defaultStyled(Mail, 26, 'primary')`
  `,
  Notifications: defaultStyled(NotificationsOutline, 26)`
  `,
  NotificationsActive: defaultStyled(Notifications, 26, 'primary')`
  `,
}

Icon.Whatsapp = defaultStyled(LogoWhatsapp, 36, 'white')``

// todo - téma
const HeartRed = defaultStyled(Heart)`
  color: #f95159; 
`
Icon.Settings = defaultStyled(Settings, 30)`
  width: 30px;
  flex-basis: 30px;
  flex-shrink: 0;
`

Icon.Chatbox = defaultStyled(Chatbox, 26)``
Icon.HeartRed = props => <HeartRed size={28} {...props} />

Icon.MoreIcon = styled(EllipsisVertical).attrs(() => ({ size: 25 }))`
  ${({ theme: { colors } }) => css`
    cursor: pointer;
    color: ${colors.primary};
  `}
`

Icon.ChevronBack = props => <ChevronBack size={34} color="white" {...props} />
Icon.ChevronForward = props => <ChevronForward size={34} color="white" {...props} />

const shadowStyle = { filter: 'drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.25))' }
Icon.ChevronBackShadow = props =>
  <ChevronBack size={34} color="white" {...props} style={shadowStyle} />

Icon.ChevronForwardShadow = props =>
  <ChevronForward size={34} color="white" {...props} style={shadowStyle} />

Icon.CheckMark = props => <Checkmark size={30} {...props} />
Icon.Close = props => <Close size={30} {...props} />

Icon.Trash = props => <Trash size={22} {...props} />

Icon.Move = props => <Move size={20} {...props} />
Icon.Create = props => <Create size={20} {...props} />
Icon.Close = props => <CloseCircle size={20} {...props} />
Icon.Person = props => <Person size={18} {...props} />
Icon.People = props => <People size={20} {...props} />

const Circle = styled.div`
  ${({ color, size, opacity = 0.65, pointer, noShadow }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${color};
    width: ${size}px;
    height: ${size}px;
    opacity: ${opacity};
    box-shadow: 3px 4px 15px rgba(0, 0, 0, 0.3); // todo - nepoužít z orbitu?
    cursor: ${pointer ? 'pointer' : 'inherit'};
    ${noShadow &&
    css`
      box-shadow: none;
    `}
  `}
`
const getInnerSize = (size, coef = 0.8) => size * coef

Icon.Help = ({ size = '22', ...props }) => <HelpCircle size={size} {...props} />
Icon.Alert = ({ size = '22', ...props }) => <AlertCircle size={size} {...props} />

Icon.Yes = ({ size = 100, onClick, ...props }) => (
  <Circle color="#E9F7EF" size={size} onClick={onClick} pointer={!!onClick}>
    <Checkmark size={getInnerSize(size)} color="#27AE60" {...props} />
  </Circle>
)
Icon.Success = ({ size = 100, onClick, noShadow, ...props }) => (
  <Circle color="#D4EFDF" size={size} onClick={onClick} pointer={!!onClick} noShadow>
    <Checkmark size={getInnerSize(size)} color="#27AE60" {...props} />
  </Circle>
)
Icon.No = ({ size = 80, onClick, ...props }) => (
  <Circle color="#FDB9BD" size={size} onClick={onClick} pointer={!!onClick}>
    <Close size={getInnerSize(size)} color="#F95159" {...props} />
  </Circle>
)
Icon.Like = ({ size = 80, onClick, isFilled, ...props }) => (
  <Circle
    color="#E8EDF1"
    size={size}
    opacity={0.8}
    onClick={onClick}
    pointer={!!onClick}
    {...props}
  >
    {isFilled
      ? <HeartRed size={getInnerSize(size, 0.7)} />
      : <HeartOutline size={getInnerSize(size, 0.7)} color="#FFFFFF" />}
  </Circle>
)
Icon.StarLike = ({ size = 80, onClick, isFilled, ...props }) => (
  <Circle
    color="#E8EDF1"
    size={size}
    opacity={0.8}
    onClick={onClick}
    pointer={!!onClick}
    {...props}
  >
    {isFilled
      ? <Star size={getInnerSize(size, 0.6)} color="#FF9A3D" />
      : <StarOutline size={getInnerSize(size, 0.6)} color="#FFFFFF" />}
  </Circle>
)
// todo - přidat padingy k šipkám, aby to bylo víc vizuálně zarovnáno
Icon.Next = ({ size = 60, onClick, ...props }) => (
  <Circle color="#BAC7D5" size={size} onClick={onClick}>
    <ChevronForward size={getInnerSize(size, 0.7)} color="#5F738C" {...props} />
  </Circle>
)
Icon.Back = ({ size = 60, onClick, ...props }) => (
  <Circle color="#BAC7D5" size={size} onClick={onClick}>
    <ChevronBack size={getInnerSize(size, 0.7)} color="#5F738C" {...props} />
  </Circle>
)

export default Icon
