import { DefaultSeo, NextSeo } from 'next-seo'

export const DefaultSEO = () => (
  <DefaultSeo
    titleTemplate={`%s | ${process.env.NEXT_PUBLIC_APP_NAME}`}
    defaultTitle={process.env.NEXT_PUBLIC_APP_NAME}
    openGraph={{
      type: 'website',
      locale: 'cs_CZ',
      url: process.env.NEXT_PUBLIC_APP_URL,
      site_name: process.env.NEXT_PUBLIC_APP_NAME,
    }}
    description="Najděte ty nejlepší videotvůrce pro váš projekt"
    // twitter={{
    //   handle: '@handle',
    //   site: '@site',
    //   // cardType: 'summary_large_image',
    // }}
  />
)

export default function SEO({ title, socialTitle, description }) {
  return (
    <NextSeo
      title={title}
      description={description}
      // canonical="https://www.canonical.ie/"
      // openGraph={{
      //   url: 'https://www.url.ie/a',
      //   title: socialTitle || title,
      //   description: 'Open Graph Description',
      //   images: [
      //     {
      //       url: 'https://www.example.ie/og-image-01.jpg',
      //       width: 800,
      //       height: 600,
      //       alt: 'Og Image Alt',
      //     },
      //     {
      //       url: 'https://www.example.ie/og-image-02.jpg',
      //       width: 900,
      //       height: 800,
      //       alt: 'Og Image Alt Second',
      //     },
      //     { url: 'https://www.example.ie/og-image-03.jpg' },
      //     { url: 'https://www.example.ie/og-image-04.jpg' },
      //   ],
      //   site_name: 'SiteName',
      // }}
    />
  )
}
