import yup, { passwordValidation } from 'lib/yup'

// TODO: can be more complex
export const userChangePasswordSchema = yup.object({
  oldPassword: yup.string().required().label('Původní heslo'),
  password: passwordValidation,
  passwordConfirmation: yup
    .string()
    .required()
    .oneOf([yup.ref('password')], 'Hesla se musí shodovat')
    .label('Heslo'),
})

export const resetPasswordSchema = yup.object({
  password: passwordValidation,
  passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Hesla se musí shodovat'),
})

export const emailSchema = yup.object({
  email: yup.string().email().required().label('E-mail'),
})

export const resetPasswordSchemaServer = resetPasswordSchema.shape({
  token: yup.string().required().min(10),
})

export const getResetPasswordDecodedTokenSchema = () =>
  yup.object({
    id: yup.objectId(),
    validTill: yup.date().required().min(new Date()), // is in future
    hashStamp: yup.string().required(),
  })
