import Link from 'next/link'
import { Stack, Heading, Text } from '@kiwicom/orbit-components'
import { Page, PageContent } from 'src/components/Page'
import Gap from 'lib/components/Gap'
import BetterLink from 'src/components/BetterLink'
import BetterText from 'src/components/BetterText'
import { links } from 'src/links'
import styled from 'styled-components'
import Row from 'lib/components/Row'
import Col from 'lib/components/Col'
import Icon from 'src/components/Icon'
import { format } from 'date-fns'

const config = [
  {
    title: 'Platforma',
    options: [
      { title: 'Tvůrci', link: links.CREATORS },
      { title: 'Referenční videa', link: links.VIDEOS },
      { title: 'Časté otázky', link: links.FAQ },
      { title: 'Blog', link: links.BLOG },
    ],
  },
  {
    title: 'Společnost',
    options: [
      { title: 'Podmínky služby', link: '/podminky.pdf', external: true },
      { title: 'Podpora projektu EU', link: links.EU },
      { title: 'Kontakt', link: links.CONTACT },
    ],
  },

  {
    title: 'Nejhledanější',
    options: [
      {
        title: 'Svatební video',
        link: { pathname: links.VIDEOS, query: { videa: 'svatebni' } },
      },
      {
        title: 'Svatební kameraman',
        link: { pathname: links.CREATORS, query: { videa: 'svatebni', dovednosti: 'kamera' } },
      },
      {
        title: 'Tvůrci v Praze',
        link: { pathname: links.CREATORS, query: { lokalita: 'hlavnimestopraha' } },
      },
      {
        title: 'Eventové video',
        link: { pathname: links.VIDEOS, query: { dovednosti: 'dramaturgie' } },
      },
      {
        title: 'Firemní video',
        link: { pathname: links.VIDEOS, query: { videa: 'firemni' } },
      },
    ],
  },
  {
    title: 'Účet',
    options: [
      { title: 'Můj profil', link: links.PROFILE },
      { title: 'Registrace', link: links.AUTH_REGISTER },
      { title: 'Přihlášení', link: links.AUTH },
    ],
  },
]

const Footer = () => (
  <Page bg="white">
    <Gap gap="64px" />
    <PageContent maxWidth="1000px">
      <Row justifyContent="space-between" responsive width="100%">
        <Col justifyContent="flex-start" mobileAlignItems="center" alignItems="center">
          <Gap gap="40px" desktop />
          <Col alignItems="center">
            <div>
              <img src="/images/logo.svg" alt="" />
            </div>
            <Gap gap="8px" />
            <div>videotvurci.cz</div>
          </Col>
          <Gap mobileGap="24px" gap="24px" />

          <Row>
            <a
              href="https://www.facebook.com/videotvurci.cz"
              target="_blank"
              rel="noreferrer nofollow"
            >
              <Icon.Links.Facebook />
            </a>

            <Gap gap="16px" />
            <a
              href="https://www.instagram.com/videotvurci.cz/"
              target="_blank"
              rel="noreferrer nofollow"
            >
              <Icon.Links.Instagram />
            </a>

            <Gap gap="19px" />
            <a
              href="https://www.linkedin.com/company/86224739"
              target="_blank"
              rel="noreferrer nofollow"
            >
              <Icon.Links.Linkedin />
            </a>
          </Row>
        </Col>

        <Gap mobileGap="24px" gap="0" />

        {config.map(({ title, options }, key) => (
          <div key={key}>
            <Gap gap="28px" />
            <Stack direction="column">
              <Heading type="title5">{title}</Heading>
              {options.map(({ title, link, external }, key) => (
                <BetterLink noUnderline href={link} key={key} external={external} type="secondary">
                  {title}
                </BetterLink>
              ))}
            </Stack>
          </div>
        ))}
      </Row>
      <Gap gap="32px" />
      <Gap gap="32px" />
      <Stack direction="row" justify="center">
        <Text>© {format(new Date(), 'yyyy')} Empty Slot s.r.o.</Text>
      </Stack>
      <Gap gap="32px" />
      <Stack direction="row" justify="center">
        <BetterLink
          type="secondary"
          noUnderline
          size="small"
          target="_blank"
          href="https://forms.gle/c69TFiEJrmeR1eoRA"
          external
        >
          Zaslat zpětnou vazbu
        </BetterLink>
      </Stack>
    </PageContent>
    <Gap gap="54px" />
  </Page>
)

export default Footer
