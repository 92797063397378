import { QueryClient, useQuery } from 'react-query'
import { dehydrate } from 'react-query/hydration'

export const isRevisit = context => context?.req?.originalUrl?.endsWith('.json')

/**
 * EXAMPLE USAGE:
  export const getServerSideProps = withSkipRevisit(async context => {
    const queryClient = new QueryClient()
    // needs to be prefetched for SSR!
    await prefetchOffers(queryClient)

    return {
      props: {
        dehydratedState: dehydrate(queryClient),
      },
    }
  })
 */

export const withSkipRevisit = callback => async context => {
  if (isRevisit(context)) {
    // console.log('skipping SPA getServerSideProps')
    return { props: {} }
  }

  return callback(context)
}

/**
 even more abstraction here
  EXAMPLE:
  export const getServerSideProps = createBasicServerSideProps({
    prefetchQueries: [
      prefetchOffers
    ]
  })
 */
export const createBasicServerSideProps = ({
  prefetchQueries = [],
  prefetchQueriesWithParams = [],
  prefetchInfiniteQueriesWithParams = [],
  addProps = () => {},
  addPropsBeforeQueries = () => {},
  transformQuery = queryParams => queryParams,
} = {}) =>
  withSkipRevisit(async context => {
    const beforeProps = await addPropsBeforeQueries(context)

    if (beforeProps?.notFound) {
      return beforeProps
    }

    const queryClient = new QueryClient()

    const promises = [
      ...prefetchQueries.map(query => query(queryClient)),
      ...prefetchQueriesWithParams.map(query => query(transformQuery(context.query))(queryClient)),
      ...prefetchInfiniteQueriesWithParams.map(query =>
        query(transformQuery(context.query))(queryClient)),
    ]

    // console.log('transformQuery(context.query)', transformQuery(context.query))

    await Promise.all(promises)

    const props = addProps(context)

    // next vs. rq bug workaround
    const dehydratedState = dehydrate(queryClient)
    // console.log('dehydratedState.queries', dehydratedState.queries)
    // console.log('dehydratedState.queries[0].state.data.pageParams', dehydratedState.queries[0].state.data.pageParams)
    if (dehydratedState.queries[0]?.state?.data?.pageParams) {
      dehydratedState.queries[0].state.data.pageParams = [null]
    }

    return {
      ...props,
      props: {
        ...props?.props,
        dehydratedState,
      },
    }
  })

// e.g. for converting czech (human readable / seo friendly) URL query params (e.g. ?cena-od=100) code,
// and transforming values (like string splitting into array),
// define in model as transformQuery (and then use it on the page you want to filter)
// transformQuery is used for filtering - both on SSR and in browser
// for options you can use dynamic getTransformQueryOptions(model)
// or create your own:
// 1) simple mapping czechKey: dbKey (e.g. cena: 'price')
// 2) complex directive:
// czechKey: {  key: fieldKey,
// split: true, (split arrays by commas)
// slugsAndValues: fieldConf.options,
// }
export const createTransformQuery = transformQueryOptions => query => {
  const result = {}

  // console.log('transformQueryOptions',transformQueryOptions )

  Object.keys(query)
    .filter(key => query[key])
    .forEach(key => {
      let newKey = key
      let newValue = query[key]

      const transformOpt = transformQueryOptions[key]

      // console.log('transformOpt', transformOpt)

      if (typeof transformOpt === 'string') {
        newKey = transformOpt
      }
      if (typeof transformOpt === 'object') {
        newKey = transformOpt.key || newKey

        if (transformOpt.split) {
          newValue = newValue.split(',')
        }
        if (transformOpt.slugsAndValues) {
          let values = newValue
          let wasSingle = false
          if (!Array.isArray(values)) {
            wasSingle = true
            values = [newValue]
          }

          values.forEach((value, index) => {
            const opt = transformOpt.slugsAndValues.find(opt => (opt.slug || opt.value) === value)
            if (opt) {
              values[index] = opt.value ?? value
            }
          })

          if (wasSingle) {
            // eslint-disable-next-line prefer-destructuring
            newValue = values[0]
          } else {
            newValue = values
          }
        }
      }

      result[newKey] = newValue
    })

  return result
}

export function return404() {
  return {
    notFound: true,
  }
}

export function getImageSrc(src, placeholderPath = '') {
  return src ? `${process.env.NEXT_PUBLIC_APP_URL}${src}` : placeholderPath
}
