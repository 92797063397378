// all models defs need to be listed and exported here!!!

// import post from './post'
import user from './user'
import video from './video'
import connection from './connection'
// import offer from './offer'
import message from './message'
// import conversation from './conversation'
// import connection from './connection'

export const allModels = [user, video, connection, message]
