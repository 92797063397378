import useIsMounted from 'lib/hooks/isMounted'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import PageLoader from 'src/components/PageLoader'
import { useAuth } from './useAuth'

export default function Protected({ redirectTo, children }) {
  const mounted = useIsMounted()

  const { user } = useAuth()

  const router = useRouter()

  useEffect(() => {
    if (!user && mounted) {
      router.push(`${redirectTo}?r=${router.asPath}`)
    }
  }, [user, redirectTo, router, mounted])

  if (!user || !mounted) {
    // console.log('without user')
    return <PageLoader />
  }
  // console.log('WITH user', user)

  return children
}
