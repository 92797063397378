import { FieldTypes } from 'lib/models/safe'

export const filterTypes = {
  RANGE: 'RANGE',
  RADIO: 'RADIO',
  MULTISELECT: 'MULTISELECT',
  SELECT: 'SELECT',
}

const isRangeField = fieldConf =>
  fieldConf && fieldConf.type === FieldTypes.Number && fieldConf.filterType === filterTypes.RANGE

// presets for converting filter values to browser url (seo / human friendly)
// use it in field config - filterValueToBrowserUrl
export const filterValueToBrowserUrlPresets = {
  single: (value, fieldConf) =>
    findFilterSlugOrValue(fieldConf.filterOptions || fieldConf.options, value),
  array: (value, fieldConf) =>
    value
      ?.map?.(v => findFilterSlugOrValue(fieldConf.filterOptions || fieldConf.options, v))
      .join(','),
  // we can add more here, e.g. booleans...
}

// creates basic transform of filters values to browser query
// add this to model, so filters are transpiled to browser url correctly
// --> this is used to get values from components / formik and convert them into
// browser url params
export const createGetBrowserFilterQueryFunction = model => values => {
  const { fields } = model
  const query = Object.keys(values).reduce((result, fieldKey) => {
    // console.log('fieldKey', fieldKey, values)
    let fieldConf = fields[fieldKey]
    const fieldValue = values[fieldKey]

    // if there is no config, keep the original key and value
    if (!fieldConf) {
      result[fieldKey] = fieldValue
      return result
    }

    const resultKey = fieldConf.filterSlug || fieldKey

    // automatically add ranges
    if (fieldKey.endsWith('From')) {
      const realFieldKey = fieldKey.substring(0, fieldKey.length - 4)
      if (isRangeField(fields[realFieldKey])) {
        fieldConf = fields[realFieldKey]
        result[`${fieldConf.filterSlug}-od`] = fieldValue
        return result
      }
    }

    if (fieldKey.endsWith('To')) {
      const realFieldKey = fieldKey.substring(0, fieldKey.length - 2)
      if (isRangeField(fields[realFieldKey])) {
        fieldConf = fields[realFieldKey]
        result[`${fieldConf.filterSlug}-do`] = fieldValue
        return result
      }
    }

    result[resultKey] = fieldValue

    // console.log('fieldValue', fieldValue)

    // auto convert some field types
    // handle selects
    if (fieldConf.type === FieldTypes.Select && fieldConf.filterType === filterTypes.RADIO) {
      // single choice
      result[resultKey] = filterValueToBrowserUrlPresets.single(fieldValue, fieldConf)
    } else if (fieldConf.type === FieldTypes.Select || fieldConf.type === FieldTypes.TextArray) {
      // multi choice
      result[resultKey] = filterValueToBrowserUrlPresets.array(fieldValue, fieldConf)
    }

    // modern use - define it in model directly without magic
    if (fieldConf.filterValueToBrowserUrl) {
      result[resultKey] = fieldConf.filterValueToBrowserUrl(fieldValue, fieldConf)
    }

    return result
  }, {})

  return query
}

function findFilterSlugOrValue(options, value) {
  const opt = options?.find(({ value: v }) => value === v)

  return opt?.slug || opt?.value || undefined
}

// creates options (set of directives) used in `createTransformQuery`
// to convert seo / humanized url query params to fields
// used for filters
// dynamically creates following
// 1) simple mapping czechKey: dbKey (e.g. cena: 'price')
// 2) complex directive:
// czechKey: {  key: fieldKey,
// split: true, (split arrays by commas)
// slugsAndValues: fieldConf.options,
// }
export const getTransformQueryOptions = model => {
  const { fields } = model

  return (
    Object.keys(fields)
      // .filter(fieldKey => !!fields[fieldKey].filterSlug)
      .reduce((result, fieldKey) => {
        const fieldConf = fields[fieldKey]

        const optKey = fields[fieldKey].filterSlug || fieldKey

        if (isRangeField(fieldConf)) {
          result[`${optKey}-od`] = `${fieldKey}From`
          result[`${optKey}-do`] = `${fieldKey}To`
        } else if (
          // (fieldConf.type === FieldTypes.Select && // <-- tohle je podle me zbytecne, staci druhy radek
          fieldConf.filterType === filterTypes.RADIO ||
          fieldConf.filterType === filterTypes.SELECT
        ) {
          result[optKey] = {
            key: fieldKey,
            slugsAndValues: fieldConf.filterOptions || fieldConf.options,
          }
        } else if (fieldConf.filterType === filterTypes.MULTISELECT) {
          result[optKey] = {
            key: fieldKey,
            split: true,
            slugsAndValues: fieldConf.filterOptions || fieldConf.options,
          }
        } else {
          result[optKey] = fieldKey
        }
        // if needed - we can add direct config to field
        // modern use:
        // if (fieldConf.transformBrowserUrlToValueOptions) {
        //   result[optKey] = fieldConf.transformBrowserUrlToValueOptions
        // }
        return result
      }, {})
  )
}

// maps translated query to query for API (with sort, filters, mongo queries etc.)
// e.g. from { region: ['praha'] } to
// { filters: {region: $in: ['praha', 'stredoceskykraj'] }, sort: 'xxx', page: '...'  }
export const getMapQueryToApiQueryParams = model => query => {
  const { sort, ...restOfQuery } = query

  const { fields } = model

  const filters = {}
  Object.keys(restOfQuery).forEach(queryFieldKey => {
    const queryValue = restOfQuery[queryFieldKey]
    if (!queryValue && queryValue !== 0) return
    let fieldConf = fields[queryFieldKey]

    if (!fieldConf && queryFieldKey.endsWith('From')) {
      const realFieldKey = queryFieldKey.substring(0, queryFieldKey.length - 4)
      fieldConf = fields[realFieldKey]
      if (fieldConf.filterType === 'RANGE') {
        if (!filters[realFieldKey]) filters[realFieldKey] = {}
        filters[realFieldKey].$gte = queryValue
        return
      }
    }
    if (!fieldConf && queryFieldKey.endsWith('To')) {
      const realFieldKey = queryFieldKey.substring(0, queryFieldKey.length - 2)
      fieldConf = fields[realFieldKey]
      if (fieldConf.filterType === 'RANGE') {
        if (!filters[realFieldKey]) filters[realFieldKey] = {}
        filters[realFieldKey].$lte = queryValue
        return
      }
    }

    if (!fieldConf) return

    if (fieldConf.type === 'TextArray' || fieldConf.filterType === filterTypes.MULTISELECT) {
      const operand = fieldConf.filterOperand === 'OR' ? '$in' : '$all'

      // auto append null if we look for 'false' -- moved to lib/router
      // if (Array.isArray(queryValue) && queryValue.some(value => value === false)) {
      //   queryValue = [...queryValue, 'NULL']
      // }

      filters[queryFieldKey] = { [operand]: queryValue }

      return
    }

    // todo - dodělat obecně
    if (fieldConf.type === 'Date') {
      filters[queryFieldKey] = { $lte: queryValue }
      return
    }

    filters[queryFieldKey] = queryValue
  })

  const result = {
    filter: {
      ...filters,
    },
    sort: sort || null, // has to be null for SSR
  }
  return {
    ...result,
    // TODO: offset, pagination -- i na SSR pro seo ==> pridat queryParam do URL jakozto strankovani!
  }
}
