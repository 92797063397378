// keep it universal so we can use it everywhere

export function addMongoListeners(mongoose, logger, slacker = { notify: () => {} }) {
  if (!logger) {
    logger = {
      info: console.log,
      error: console.error,
      fatal: console.error,
    }
  }

  const notify = msg => {
    // process.env.SLACK_APP_NAME
    slacker.notify(
      `*${process.env.SLACK_APP_NAME}*
        ${msg}
      `,
    )
  }

  const db = mongoose.connection

  db.on('connecting', function () {
    logger.info('connecting to MongoDB...')
  })

  db.on('error', function (error) {
    logger.fatal(`Error in MongoDb connection: ${error}`)
    mongoose.disconnect()
    notify(`Error in MongoDb connection: ${error}`)
  })
  db.on('connected', function () {
    logger.info('MongoDB connected!')
  })
  db.on('open', function () {
    logger.info('MongoDB connection opened!')
  })
  db.on('reconnected', function () {
    logger.info('MongoDB reconnected!')
    notify('MongoDB reconnected!')
  })
  db.on('reconnectFailed', function () {
    logger.error('MongoDB maximum reconnects failed!')
    notify('MongoDB maximum reconnects failed!')
  })
  db.on('attemptReconnect', function () {
    logger.info('MongoDB try to reconnect!')
  })
  db.on('close', async function () {
    logger.error('MongoDB connection closed!')
  })
  db.on('disconnected', async function () {
    logger.fatal('MongoDB disconnected! Triggering reconnect attempts!')
    notify('MongoDB disconnected! Triggering reconnect attempts!')
  })
}

export const areMongoIdsSame = (id1, id2) => {
  if (id1._id) {
    id1 = id1._id
  }
  if (id2._id) {
    id2 = id2._id
  }

  const id1str = id1?.toString() || id1
  const id2str = id2?.toString() || id2

  return id1str === id2str
}

export const ensureMongoIdString = id => id?.toString() || id

export const mapMongoIdsToString = arr => arr.map(ensureMongoIdString)

export const includesMongoId = (arr, id) => {
  const mapped = mapMongoIdsToString(arr)
  return mapped.includes(ensureMongoIdString(id))
}
