import { Provider } from 'react-redux'
import Head from 'next/head'
import Link from 'next/link'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Hydrate } from 'react-query/hydration'

import { ReactQueryDevtools } from 'react-query/devtools'
import { useCallback, useRef } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import ThemeProvider from '@kiwicom/orbit-components/lib/ThemeProvider'

import csCZ from '@kiwicom/orbit-components/lib/data/dictionary/cs-CZ.json'

import { getInMemoryTokens, ProvideAuth, useAuth, useFirstLogin } from 'lib/auth/useAuth'
import NavBar from 'src/components/NavBar'
import Footer from 'src/components/Footer'
import Gap from 'lib/components/Gap'
import { GlobalStyle, theme } from 'src/styles'
import Col from 'lib/components/Col'
import Protected from 'lib/auth/Protected'
import { QueryConfigProvider } from 'lib/queries'
import { links } from 'src/links'
import { useRouter } from 'next/router'
import { DefaultSEO } from 'src/components/SEO'

import { DndProvider } from 'react-dnd'
import { TouchBackend } from 'react-dnd-touch-backend'
import { HTML5Backend } from 'react-dnd-html5-backend'

import Bugsnag from 'lib/bugsnag'
import { useGtag } from 'lib/gtag'
import WAButton from 'src/components/WAButton'
import IntroModal from 'src/components/IntroModal'
import Error from './_error'

const ErrorBoundary = Bugsnag.getPlugin('react')

const Wrapper = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`

function MyApp({ Component, pageProps }) {
  const queryClientRef = useRef()
  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient()
    // console.log('queryClientRef.current', queryClientRef.current)
  }

  const [isFirstLogin, setIsFirstLogin] = useFirstLogin()

  // console.log('isFirstLogin', isFirstLogin)

  useGtag()

  const router = useRouter()

  const onTokenExpired = useCallback(() => {
    console.log('_app on token is expired --> SIGN_OUT')

    router.push(links.SIGN_OUT)
  }, [router])

  return (
    <>
      <ErrorBoundary FallbackComponent={Error}>
        <QueryClientProvider client={queryClientRef.current}>
          <Hydrate state={pageProps.dehydratedState}>
            <ProvideAuth onTokenExpired={onTokenExpired}>
              <ThemeProvider theme={theme} dictionary={csCZ}>
                {/* todo vyřešit fallback pro drag and drop na mobilu, pokud bude třeba */}
                {/* <DndProvider backend={TouchBackend}> */}
                <>
                  <GlobalStyle />

                  <div
                    id="modals"
                    style={{
                      position: 'absolute',
                      top: 0,
                      zIndex: 100000,
                      // width: '100%',
                      // height: '100%',
                    }}
                  />

                  <DefaultSEO />

                  <Wrapper>
                    <NavBar />

                    <Gap gap="64px" mobileGap="52px" />

                    {Component.protected ? (
                      <Protected redirectTo="/auth">
                        <Component {...pageProps} />
                      </Protected>
                    )
                    : <Component {...pageProps} />}

                    <Footer />

                    {isFirstLogin && <IntroModal />}
                    <WAButton />
                  </Wrapper>

                  <ReactQueryDevtools initialIsOpen position="bottom-left" />
                </>
              </ThemeProvider>
            </ProvideAuth>
          </Hydrate>
        </QueryClientProvider>
      </ErrorBoundary>
    </>
  )
}

// export default wrapper.withRedux(MyApp);
export default MyApp
