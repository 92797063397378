import {
  Alert,
  Button,
  Dialog,
  List,
  ListItem,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Portal,
  Text,
  Textarea,
  TextLink,
} from '@kiwicom/orbit-components'
import Gap from 'lib/components/Gap'
import React, { useEffect, useState } from 'react'
import { useCreateMessageMutation } from 'src/queries/messages'
import BetterDialog from './BetterDialog'
import ModelFormik from './fields/ModelFormik'

function BetterModal({
  defaultOpen = false,
  onClose,
  children,
  confirmLeave,
  title,
  body,
  size = 'large',
}) {
  const [isModalShown, setIsModalShown] = useState(defaultOpen)

  useEffect(() => {
    if (defaultOpen) {
      setIsModalShown(true)
    }
  }, [defaultOpen])

  const closeModal = () => {
    setIsModalShown(false)
    onClose?.()
  }

  return (
    <>
      {children({ openModal: () => setIsModalShown(true) })}

      {isModalShown && (
        <BetterDialog.ConfirmLeave onConfirm={closeModal}>
          {({ openDialog: openConfirmDialog }) => (
            <Portal renderInto="modals">
              <Modal
                mobileHeader
                onClose={() => {
                  if (confirmLeave) {
                    openConfirmDialog()
                  } else {
                    closeModal()
                  }
                }}
                size={size}
              >
                <ModalHeader title={title} />
                {typeof body === 'function'
                  ? body({
                    closeModal,
                    closeModalWithConfirm: () => openConfirmDialog,
                  })
                  : body}
              </Modal>
            </Portal>
          )}
        </BetterDialog.ConfirmLeave>
      )}
    </>
  )
}

export default BetterModal

/// USE confirm dialog from BetterDialog instead!
BetterModal.Confirm = ({ title = 'Jste si jisti?', onConfirm, onCancel, ...props }) => (
  <BetterModal
    title={title}
    size="small"
    {...props}
    body={({ closeModal }) => (
      <>
        <ModalSection>
          <Text>Neuložené změny budou ztraceny</Text>
          <Gap gap="16px" />
        </ModalSection>

        <ModalFooter>
          <Button
            type="critical"
            onClick={() => {
              onConfirm?.()
              closeModal()
            }}
          >
            Zahodit změny
          </Button>
          <Button
            // type="primary"
            onClick={() => {
              onCancel?.()
              closeModal()
            }}
          >
            Vrátit se zpět
          </Button>
        </ModalFooter>
      </>
    )}
  />
)
