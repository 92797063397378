import React from 'react'
import styled, { css } from 'styled-components'
import { addResponsivity, up } from 'lib/styles'

const Row = styled.div`
  ${({
  theme: { colors },
  responsive,
  align,
  alignItems,
  alignSelf,
  justify,
  justifyContent,
  mobileJustifyContent,
  mobileAlignItems,
  mobileAlign,
  maxWidth,
  mobileMaxWidth,
  position,
  margin,
  mobileMargin,
  padding,
  mobilePadding,
  mobileWrap,
  wrap,
  width,
  shrink,
  grow,
  mobileWidth,
  height,
  bp = 'mobile',
  mobileFlexDirection,
  overflowX,
  mobileOverflowX,
  overflowY,
  mobileOverflowY,
}) => css`
    display: flex;
    align-items: ${mobileAlignItems || mobileAlign || alignItems || align};
    align-self: ${alignSelf};
    justify-content: ${mobileJustifyContent || justifyContent || justify};
    max-width: ${mobileMaxWidth || maxWidth};
    width: ${mobileWidth || width};
    height: ${height};
    position: ${position};
    flex-shrink: ${shrink};
    flex-grow: ${grow};
    margin: ${mobileMargin || margin};
    padding: ${mobilePadding || padding};
    flex-direction: ${mobileFlexDirection || (responsive ? 'column' : 'row')};
    overflow-x: ${mobileOverflowX || overflowX};
    overflow-y: ${mobileOverflowY || overflowY};
    flex-wrap: ${mobileWrap || wrap};

    ${up(bp)} {
      flex-wrap: ${wrap};
      width: ${width};
      flex-direction: row;
      margin: ${margin};
      padding: ${padding};
      align-items: ${alignItems || align};
      max-width: ${maxWidth};
      justify-content: ${justifyContent || justify};
      overflow-x: ${overflowX};
      overflow-y: ${overflowY};
    }
    /* 
    ${responsive &&
    css`
      flex-wrap: wrap;
    `} */

    ${addResponsivity({ displayAs: 'flex' })}
  `}
`

export default Row
