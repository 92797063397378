// ! since in frontend keystone / express cant be in scope, move universal code here
import { passwordValidation } from 'lib/yup'
import * as yup from 'yup'
import ExMatch from 'expression-match'

// as keystone types
export const FieldTypes = {
  Text: 'Text',
  Number: 'Number',
  Email: 'Email',
  Textarea: 'Textarea',
  Boolean: 'Boolean',
  Relationship: 'Relationship',
  Select: 'Select',
  Password: 'Password',
  TextArray: 'TextArray',
  Datetime: 'Datetime',
  Date: 'Date',
  Url: 'Url',
  Phone: 'Phone',
}

export function generateYupEnumForSelect(field) {
  const options = field.options.map(opt => opt.value)
  if (!field.required) {
    options.push(null)
  }
  const validationObj = yup.string().nullable().oneOf(
    options,
    // 'Pole musí být jedno ze seznamu: ${values}',
    'Vyberte položku ze seznamu',
  )

  return validationObj
}

export function getOnlyFields(onlyFields) {
  if (typeof onlyFields === 'string') {
    onlyFields = onlyFields.split(',').map(a => a.trim())
  }

  return onlyFields
}

// so far it handles selects enums, required and number/string fields
// you can specify what fields you want to validate (by field or string)
export function generateYupForModel(model, { onlyFields, withoutHidden } = {}) {
  onlyFields = getOnlyFields(onlyFields)

  const shape = Object.keys(model.fields)
    .filter(fieldKey => !onlyFields || onlyFields.includes(fieldKey))
    .filter(fieldKey => {
      if (!withoutHidden) {
        return true
      }

      const f = model.fields[fieldKey]
      if (f.hidden || f.hiddenApi || f.hiddenFormik) {
        return false
      }
      return true
    })
    .reduce((acc, key) => {
      const field = model.fields[key]

      let validation

      // strings
      if (
        [
          FieldTypes.Text,
          FieldTypes.Email,
          FieldTypes.Textarea,
          FieldTypes.Password,
          FieldTypes.Url,
        ].includes(field.type)
      ) {
        validation = yup.string().typeError('Musí být text')
      }
      if (field.maxlength) {
        validation = validation.max(
          field.maxlength[0],
          field.maxlength[1] || 'Pole může mít maximálně ${max} znaků', // TODO: sklonovat
        )
      }
      if (field.minlength) {
        validation = validation.min(
          field.minlength[0],
          field.minlength[1] || 'Pole musí mít minimálně ${min} znaků',
        )
      }
      if (field.type === FieldTypes.Url) {
        validation = validation.url()
      }

      // numbers
      if (field.type === FieldTypes.Number) {
        validation = yup.number().typeError('Musí být číslo')
      }
      if (field.max) {
        validation = validation.max(field.max[0], field.max[1] || 'Pole může být maximálně ${max}')
      }

      if (field.min) {
        validation = validation.min(field.min[0], field.min[1] || 'Pole musí být minimálně ${min}')
      }

      // email
      if (field.type === FieldTypes.Email) {
        validation = yup
          .string()
          .matches(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            'Není platný email',
          )
      }

      // password
      if (field.type === FieldTypes.Password) {
        validation = passwordValidation
      }

      // multiple
      if (field.type === FieldTypes.TextArray) {
        validation = yup.array()
      }

      // phone
      if (field.type === FieldTypes.Phone) {
        // validation = yup.string().phone(field.region, !!field.region)

        validation = yup.string().validatePhone()

        // validation = yup.mixed().when(key, {
        //   is: value => value,
        //   then:
        //     field.required
        //       ? yup.string().phone(field.region, !!field.region)
        //       : yup.string().phone(field.region),
        //   otherwise: validation,
        // })
      }

      // enum
      if (field.type === FieldTypes.Select) {
        validation = generateYupEnumForSelect(field)
      }

      // required
      if (field.required) {
        validation ??= yup.mixed()

        if (field.type === FieldTypes.TextArray) {
          validation = validation.min(1)
        } else {
          validation = validation.required('Pole je povinné')
        }

        // ${path} - nazev pole, ${value(s)}, ${type}
      }

      // inject label
      if (field.label) {
        validation ??= yup.mixed()
        validation = validation.label(field.label)
      }

      if (validation) {
        // todo - toto padá, když mám jen requiredDependsOn a ne dependsOn
        if (field.dependsOn || field.requiredDependsOn) {
          const dependsOnFieldKeys = Object.keys(field?.dependsOn || {})
          const requiredDependsOnFieldKeys = Object.keys(field?.requiredDependsOn || {})

          // depends on - require validation first
          const validationWithRequired = !field.requiredDependsOn
            ? validation
            : yup.mixed().when(requiredDependsOnFieldKeys, {
              is: (...values) => {
                const valuesMap = requiredDependsOnFieldKeys.reduce((acc, key, index) => {
                  acc[key] = values[index]
                  return acc
                }, {})
                // console.log('require validation', valuesMap, field.requiredDependsOn)
                const match = new ExMatch(field.requiredDependsOn, valuesMap)
                return match.match()
              },
              then:
                  field.type === FieldTypes.TextArray
                    ? validation = validation.min(1)
                    : validation.required('Pole je povinné'),
              otherwise: validation,
            })

          // only validate if field is supposed to be shown
          acc[key] = !field.dependsOn
            ? validation
            : yup.mixed().when(dependsOnFieldKeys, {
              is: (...values) => {
                const valuesMap = dependsOnFieldKeys.reduce((acc, key, index) => {
                  acc[key] = values[index]
                  return acc
                }, {})

                // console.log('normal validation', valuesMap, field.dependsOn)
                const match = new ExMatch(field.dependsOn, valuesMap)
                return match.match()
              },
              then: validationWithRequired,
            })
        } else {
          acc[key] = validation
        }
      }

      return acc
    }, {})

  return yup.object(shape)
}

// takes messy input and returns only relevant data
// TODO: dependsOn cleanup
export function selectRelevantData(
  data,
  fields,
  { withoutHidden, withoutHiddenApi, withoutHiddenFormik, removeKeys, ignoreUndefined } = {},
) {
  const updateData = {}

  let keysToRemove = []
  if (removeKeys) {
    if (Array.isArray(removeKeys)) {
      keysToRemove = removeKeys
    }
    if (typeof removeKeys === 'string') {
      keysToRemove = removeKeys.split(',').map(a => a.trim())
    }
  }

  Object.keys(fields)
    .filter(key => !keysToRemove.includes(key))
    .forEach(key => {
      const field = fields[key]
      if (withoutHidden) {
        if (field.hidden || field.hiddenApi || field.hiddenFormik) {
          return
        }
      }
      if (withoutHiddenApi) {
        if (field.hiddenApi || field.hiddenFormik) {
          return
        }
      }
      if (withoutHiddenFormik) {
        if (field.hiddenFormik) {
          return
        }
      }
      if (ignoreUndefined && typeof data[key] === 'undefined') {
        return
      }
      updateData[key] = data[key]
    })

  return updateData
}

export function shouldBeShown(field, values, options) {
  if (field.dependsOn) {
    const expression = { ...field.dependsOn }
    if (field.dependsOnEmpty) {
      let nullable = field.dependsOnEmpty
      if (typeof field.dependsOnEmpty === 'string') {
        nullable = field.dependsOnEmpty.split(',').map(a => a.trim())
      }
      nullable.forEach(fieldKey => {
        expression[fieldKey] = {
          or: [field.dependsOn[fieldKey], null, undefined, ''],
        }
      })
    }

    // console.log('expression', expression)

    const match = new ExMatch(expression, values, options)
    // const match = new ExMatch(field.dependsOn, values, options)

    const shouldShow = match.match()
    // console.log('shouldShow', shouldShow, field.dependsOn, values)
    return shouldShow
  }

  return true
}

export function shouldBeRequired(field, values, options) {
  if (field.requiredDependsOn) {
    const expression = { ...field.requiredDependsOn }
    const match = new ExMatch(expression, values, options)
    return match.match()
  }

  if (field.required) return true

  return undefined
}

// helper for options handling
export function getValues(options) {
  return options.reduce((acc, option) => {
    acc[option.value] = option.value
    return acc
  }, {})
}

// converts array to object
export function getOptionsMap(options) {
  return options.reduce((acc, option) => {
    acc[option.value] = option
    return acc
  }, {})
}
