import { Button, List, ListItem, ModalFooter, ModalSection, Text } from '@kiwicom/orbit-components'
import Gap from 'lib/components/Gap'
import React from 'react'
import styled from 'styled-components'
import { format } from 'date-fns'
import { Player, Controls } from '@lottiefiles/react-lottie-player'
import { up } from 'lib/styles'
import { links } from 'src/links'
import { useFirstLogin } from 'lib/auth/useAuth'
import BetterModal from './BetterModal'
import BetterButtonLink from './BetterButtonLink'

const AnimWrapper = styled.div`
  position: absolute;
  top: -0px;
  height: 500px;
  width: 540px;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;

  ${up('mobile')} {
    overflow: auto;
    max-height: none;
  }
`
const StyledPlayer = styled(Player)`
  height: 500px;
  width: 540px;
  max-width: 100%;
`

const IntroModal = ({ title, ...props }) => {
  const [isFirstLogin, setIsFirstLogin] = useFirstLogin()

  return (
    <BetterModal
      title="Vítejte - nastavte si svůj profil"
      size="small"
      defaultOpen
      isOpen
      {...props}
      body={({ closeModal }) => (
        <>
          <AnimWrapper>
            <StyledPlayer
              autoplay
              // loop
              src="https://assets1.lottiefiles.com/packages/lf20_5upjwxvm.json"
              src="https://assets4.lottiefiles.com/packages/lf20_rovf9gzu.json"
              src="/lottie/confetti.json"
            />
          </AnimWrapper>
          <ModalSection>
            <Text>
              Ať už jste tvůrce a nebo zákazník, zamiřte do nastavení a vyplňte si profil!
            </Text>
            <Gap gap="16px" />
          </ModalSection>

          <ModalFooter>
            <BetterButtonLink
              type="primary"
              href={links.PROFILE}
              onClick={() => {
                setIsFirstLogin(false)
                closeModal()
              }}
            >
              Nastavit profil
            </BetterButtonLink>
          </ModalFooter>
        </>
      )}
    >
      {({ openModal }) => <div />}
    </BetterModal>
  )
}

export default IntroModal
