import React, { useState } from 'react'
import NextLink from 'next/link'
import { links } from 'src/links'
import { Button, TextLink } from '@kiwicom/orbit-components'

// const WrappedLink = React.forwardRef(({ onClick, href, children }, ref) => (
//   <TextLink href={href} onClick={onClick} ref={ref}>
//     {children}
//   </TextLink>
// ))

const BetterButtonLink = ({ children, href, ...rest }) => (
  <NextLink href={href} passHref {...rest}>
    <Button {...rest}>{children}</Button>
  </NextLink>
)

export default BetterButtonLink

BetterButtonLink.links = links
