import React, { useState } from 'react'
import NextLink from 'next/link'
import { links } from 'src/links'
import { TextLink } from '@kiwicom/orbit-components'
import styled from 'styled-components'

export const PureLink = styled.a`
  color: inherit;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    color: inherit;
    text-decoration: none;
  }
`

// const WrappedLink = React.forwardRef(({ onClick, href, children }, ref) => (
//   <TextLink href={href} onClick={onClick} ref={ref}>
//     {children}
//   </TextLink>
// ))

// if you want to use target blank, use normal link instead, OR prop "external" (used by orbit)
const BetterLink = ({ children, href, ...rest }) => (
  <NextLink href={href} passHref {...rest}>
    <TextLink {...rest}>{children}</TextLink>
  </NextLink>
)

// without styling
BetterLink.Pure = ({ children, onClick, ...rest }) => (
  <NextLink {...rest} passHref>
    <PureLink onClick={onClick}>{children}</PureLink>
  </NextLink>
)

export default BetterLink

Object.keys(links).forEach(key => {
  BetterLink[key] = links[key]
})

BetterLink.links = links
