import useIsMounted from 'lib/hooks/isMounted'
import isBrowser from 'lib/isBrowser'
import { createApiCall, handleTokenError, useConfiguredQuery } from 'lib/queries'
import { useRouter } from 'next/router'
import React, { useState, useEffect, useMemo, useContext, createContext, useCallback } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { links } from 'src/links'
import useLocalStorageState from 'use-local-storage-state'

// in memory tokens, so normal fns can require them
let inMemoryTokens = {}
export function getInMemoryTokens() {
  return inMemoryTokens
}

export function useFirstLogin() {
  const [isFirstLogin, setIsFirstLogin] = useLocalStorageState(
    `${process.env.NEXT_PUBLIC_APP_NAME}-isFirstLogin`,
    null,
  )

  return [isFirstLogin, setIsFirstLogin]
}

const authContext = createContext({})

const userQueryKey = ['users', 'me']

const signInCall = createApiCall({ path: 'auth/sign-in', method: 'POST' })
const fetchUser = createApiCall({ path: 'users/me' })

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children, onTokenExpired }) {
  const queryClient = useQueryClient()
  const [tokens, setLocalTokens] = useLocalStorageState(
    `${process.env.NEXT_PUBLIC_APP_NAME}-tokens`,
    {
      ssr: true,
      defaultValue: null,
    },
  )

  const setTokens = useCallback(
    tokens => {
      // console.log('settings tokens to', tokens)
      inMemoryTokens = tokens
      setLocalTokens(tokens)
    },
    [setLocalTokens],
  )

  const [localUser, setLocalUser] = useLocalStorageState(
    `${process.env.NEXT_PUBLIC_APP_NAME}-user`,
    null,
  )

  const [isFirstLogin, setIsFirstLogin] = useFirstLogin()

  // if tokens in state (local storage) are updated
  // --> update their in memory copy and validate user
  useEffect(() => {
    // console.log('TOKENS changes!', tokens)
    inMemoryTokens = tokens
    queryClient.invalidateQueries(userQueryKey)
  }, [tokens, queryClient])

  const {
    data: user,
    remove: removeUserData,
    refetch: refetchUser,
  } = useQuery(userQueryKey, () => fetchUser({ tokens }), {
    onError: error => {
      handleTokenError(error, onTokenExpired)
      if (error?.errors?.some(e => e.name === 'RestrictedArea')) {
        setTokens(null)
        // queryClient.invalidateQueries(userQueryKey)
      }
    },
    onSuccess: user => {
      setLocalUser(user)
    },
    enabled: !!tokens,
    initialData: localUser,
  })

  function refreshMe(delayed) {
    // queryClient.invalidateQueries(userQueryKey)
    queryClient.cancelQueries(userQueryKey)

    if (delayed) {
      setTimeout(() => {
        refetchUser({ cancelRefetch: true })
      }, 800)
    }
  }

  const signInMutation = useMutation(data => signInCall({ data }), {
    onSuccess: ({ user, tokens }) => {
      console.log('sign is success', user, tokens)
      setTokens(tokens)
      setLocalUser(user)
      setIsFirstLogin(user.isFirstLogin)
      // this refreshes query to take initialData (from local storage set above) in account
      removeUserData()
    },
    onError: error => {
      console.error('login error', error)
    },
  })

  const signOut = useCallback(
    async cb => {
      console.log('start log out')
      setTokens(null)
      setLocalUser(null)
      queryClient.setQueryData(userQueryKey, null)
      queryClient.resetQueries(userQueryKey)
      queryClient.removeQueries(userQueryKey, { exact: true })

      cb?.()
    },
    [setTokens, setLocalUser, queryClient],
  )

  // console.log('ProvideAuth renders, user data', user, 'localUser', localUser, 'tokens', tokens)

  // for convenience
  const isMounted = useIsMounted()

  const finalUser = tokens ? user : null

  // we want render user data only after component was mounted, when we use SSR
  const mountedUser = isMounted ? finalUser : null

  const router = useRouter()
  function redirectToAuth({ r } = {}) {
    router.push(`/auth?r=${r || router.asPath}`)
  }

  // on public (SSR) pages use mountedUser, which is exposed after didMount
  // and does not trigger hydration inconsistency
  const providedState = {
    user: finalUser,
    mountedUser,
    signInMutation,
    signOut,
    refreshMe,
    setTokens,
    tokens,
    onTokenExpired,
    redirectToAuth,
  }

  return <authContext.Provider value={providedState}>{children}</authContext.Provider>
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => useContext(authContext)
// on public (SSR) pages use mountedUser, which is exposed after didMount
// and does not trigger hydration inconsistency
