// helper for bugsnag, called both on server and browser
export function applyMetadata(event, customMetadata, metadata) {
  event.addMetadata('custom', { ...customMetadata })
  Object.keys(metadata).forEach(key => {
    event.addMetadata(key, {
      ...event.getMetadata(key), // preserve original metadata
      ...metadata[key],
    })
  })
}
