/**
 * Helpers for selects: keystone select / react select / orbit select
 */

// creates list of options for select DB / react select / orbit select / filters
// e.g. [{ label: 'Týmy',value: 'tymy', slug: 'tymy',]
// slug is for browser filter query
export const createList = data =>
  data.map(d => ({ label: d, value: getValue(d), slug: getValue(d) }))

/**
 * returns object with labels mapped to keys(values), e.g. from
 * [{ label: 'Žena', value: 'female' }]
 * ==>
 * {
 *  female: 'Žena'
 * }
 *
 *  */
export function getValuesToLabels(options, form) {
  return options.reduce((acc, option) => {
    if (form === 2) {
      acc[option.value] = option.label2
    } else {
      acc[option.value] = option.label
    }
    return acc
  }, {})
}

export const getValue = s => {
  let r = s.toLowerCase()
  r = r.replace(new RegExp(/\s/g), '')
  r = r.replace(new RegExp(/[àáâãäå]/g), 'a')
  r = r.replace(new RegExp(/æ/g), 'ae')
  r = r.replace(new RegExp(/ç/g), 'c')
  r = r.replace(new RegExp(/[èéêëě]/g), 'e')
  r = r.replace(new RegExp(/[ìíîï]/g), 'i')
  r = r.replace(new RegExp(/ñň/g), 'n')
  r = r.replace(new RegExp(/č/g), 'c')
  r = r.replace(new RegExp(/ěé/g), 'e')
  r = r.replace(new RegExp(/ž/g), 'z')
  r = r.replace(new RegExp(/ý/g), 'y')
  r = r.replace(new RegExp(/š/g), 's')
  r = r.replace(new RegExp(/ř/g), 'r')
  r = r.replace(new RegExp(/[òóôõö]/g), 'o')
  r = r.replace(new RegExp(/œ/g), 'oe')
  r = r.replace(new RegExp(/[ùúûü]/g), 'u')
  r = r.replace(new RegExp(/[ýÿ]/g), 'y')
  r = r.replace(new RegExp(/\W/g), '')
  r = r.replace(new RegExp(/\+/g), '-')
  return r
}
