export const links = {
  HOMEPAGE: '/',
  AUTH: '/auth',
  AUTH_REGISTER: '/auth?t=signup',
  SIGN_OUT: '/auth/sign-out',
  PASSWORD_RESET: '/auth/reset',
  PROFILE: '/moje/profil',
  PROFILE_PREVIEW: '/moje/profil/nahled',
  PASSWORD_CHANGE: '/moje/profil/zmena-hesla',
  USER_PUBLIC_DETAIL: '/lide/[nanoid]', // verejny profil , zatim nefunkcnci
  EU: '/podpora-projektu',
  CREATORS: '/tvurci',
  CREATOR_PUBLIC_DETAIL: '/tvurci/[slug]',
  VIDEOS: '/referencni-videa',
  VIDEO_DETAIL: '/referencni-videa/[nanoid]',
  FAQ: '/caste-otazky',
  BLOG: '/blog',
  POST: '/blog/[slug]',
  CONTACT: '/kontakt',
}

/**
 * USAGE
 *  <Link
          href={{
            pathname: '/blog/[slug]',
            query: { slug: 'my-post' },
          }}
        >
 */
// za mesic smazat, az to budeme umet,...
