import * as yup from 'yup'
import { FieldTypes, generateYupForModel } from 'lib/models/safe'
import { userChangePasswordSchema } from 'lib/auth/validate'
import { createUserPreSaveHook } from 'lib/auth/safe'
import { passwordValidation } from 'lib/yup'
import { nanoid } from 'nanoid'
import axios from 'axios'
import { createList } from 'lib/selects'
import { roundNumber } from 'lib/numbers'
import { createTransformQuery } from 'lib/next'
import {
  filterTypes,
  getMapQueryToApiQueryParams,
  getTransformQueryOptions,
} from 'lib/models/filters'
import { migrationSchemaVideoTypes, skillsOptions, videoTypesOptions } from './user'

const budgetOptions = createList([
  'do 10 tis.',
  '10 - 25 tis.',
  '25 - 50 tis.',
  '50 - 100 tis.',
  '100 - 150 tis.',
  '150 - 200 tis.',
  'více jak 200 tis.',
])

export const migrationSchema = {
  budget: {
    vicejak50tis: '50100tis',
  },
  videoType: migrationSchemaVideoTypes,
}

const model = {
  name: 'Video',
  resourceName: 'videos',
  textSearchFields: ['name'],
  defaultColumns: 'name, author, deleted',
  defaultSort: '-updatedAt',
  nodelete: true,
  mapName: 'name',
  ownershipKey: 'author', // so api can automatically check when editing...
  keepTrackFields: ['createdAt'],

  fields: {
    // can be used as non deterministic id (mongoId is guessable)
    // e.g. for starting messages
    nanoid: {
      type: FieldTypes.Text,
      hiddenFormik: true,
      default: () => nanoid(5),
    },
    name: {
      type: FieldTypes.Text,
      required: true,
      index: true,
      // minlength: [4],
      label: 'Název videa',
    },

    author: {
      type: FieldTypes.Relationship,
      ref: 'User',
    },
    authorIsNotCreator: {
      type: Boolean,
    },
    // image: {
    //   label: 'Profilový obrázek',
    //   type: FieldTypes.Relationship,
    //   ref: 'VideoImage',
    // },

    skills: {
      type: FieldTypes.TextArray,
      options: skillsOptions,
      label: 'Předvedené dovednosti',
      isMultiSelect: true,
      required: true,
      filterSlug: 'dovednosti',
      filterOperand: 'AND',
      filterType: filterTypes.MULTISELECT,
    },
    budget: {
      type: FieldTypes.Select,
      options: budgetOptions,
      label: 'Rozpočet (na tvojí práci)',
      filterSlug: 'rozpocet',
      filterOperand: 'OR',
      filterType: filterTypes.MULTISELECT,
    },
    // api youtube to nevraci, ale vimeo ano
    duration: {
      type: FieldTypes.Text,
      hiddenFormik: true,
    },
    videoType: {
      type: FieldTypes.Select,
      options: videoTypesOptions,
      label: 'Typ videa',
      required: true,
      filterType: filterTypes.MULTISELECT,
      filterSlug: 'videa',
      filterOperand: 'OR',
    },
    description: {
      type: FieldTypes.Textarea,
      label: 'Popis videa',
      placeholder:
        'Popiš, co jsi ve videu předvedl, na co jsi hrdý/á, kontext videa, postup prací, odhad rozpočtu a co v něm bylo zahrnuto...',
      required: true,
      minlength: [10],
    },

    link: {
      type: FieldTypes.Url,
      required: true,
    },
    // used for creator page and user listing
    sortOrder: {
      type: FieldTypes.Number,
      // required: true,
      hiddenFormik: true,
    },
    // used for all videos listing, updated once a 4 hrs
    randomSortOrder: {
      type: FieldTypes.Number,
      hiddenFormik: true,
      // new videos are first before first shuffle
      default: -1,
    },
    thumbnail: {
      type: FieldTypes.Url,
      // required: true,
      hiddenFormik: true,
    },
    vimeoInfo: {
      type: FieldTypes.Textarea,
      // required: true,
      hiddenApi: true,
    },
    youtubeInfo: {
      type: FieldTypes.Textarea,
      // required: true,
      hiddenApi: true,
    },
    ratio: {
      type: FieldTypes.Number,
      // required: true,
      hiddenFormik: true,
    },
    deleted: {
      type: FieldTypes.Boolean,
      hiddenApi: true,
    },
  },
  // relationships: [

  // ],

  hooks: {
    preSave: [
      keystone =>
        async function (next) {
          if (!this.deleted) {
            if (this.link.startsWith('https://vimeo')) {
              try {
                // TODO dodelat IS_BROWSER
                if (!process.env.IS_BROWSER) {
                  // const { Vimeo } = require('vimeo')
                  // const vimeoClient = new Vimeo(
                  //   process.env.VIMEO_CLIENT_ID,
                  //   process.env.VIMEO_CLIENT_SECRET,
                  //   process.env.VIMEO_TOKEN,
                  // )

                  const res = await axios(
                    `https://vimeo.com/api/oembed.json?url=${encodeURIComponent(this.link)}`,
                  )

                  // console.log('resss', res.data)
                  if (!res.data.thumbnail_url) {
                    return next('Vimeo nevrátilo náhled. Kontaktujte prosím podporu.')
                  }
                  // we can define our custom resolution
                  // const thumbBase = res.data.thumbnail_url.split('_')

                  this.thumbnail = res.data.thumbnail_url
                  this.vimeoInfo = JSON.stringify(res.data)

                  this.ratio = roundNumber(res.data.width / res.data.height)
                  this.duration = res.data.duration
                }

                return next()
              } catch (error) {
                console.log('error getting vimeo thumbnail')
                console.log('errored video link', this.link)
                console.notifyWarning(
                  'Nepodařilo se načíst údaje z Vimeo. Zkontrolujte adresu. - pri ukladani videa',
                  { link: this.link, _id: this._id },
                )
                console.adminSlacker.notify(
                  `Nepodařilo se načíst údaje z Vimeo pri ukladani videa. Link: ${this.link}, _id: ${this._id}`,
                )
                return next(new Error('Nepodařilo se načíst údaje z Vimeo. Zkontrolujte adresu.'))
              }
            }
            if (this.link.startsWith('https://www.youtube')) {
              try {
                const res = await axios(
                  `https://www.youtube.com/oembed?url=${this.link}&format=json`,
                )
                if (!res.data.thumbnail_url) {
                  return next('Youtube nevrátil náhled. Kontaktujte prosím podporu.')
                }
                // we can define our custom resolution
                // const thumbBase = res.data.thumbnail_url.split('_')

                this.thumbnail = res.data.thumbnail_url
                this.youtubeInfo = JSON.stringify(res.data)

                this.ratio = roundNumber(res.data.width / res.data.height)
                this.duration = res.data.duration
                return next()
              } catch (error) {
                console.log('error getting youtube thumbnail')
                console.error('ERR video link', this.link, this._id)
                console.notifyWarning(
                  'Nepodařilo se načíst údaje z Youtube. Zkontrolujte adresu. - pri ukladani videa',
                  { link: this.link, _id: this._id },
                )
                console.adminSlacker.notify(
                  `Nepodařilo se načíst údaje z Youtube pri ukladani videa. Link: ${this.link}, _id: ${this._id}`,
                )
                return next(
                  new Error(
                    `Nepodařilo se načíst údaje z Youtube. Zkontrolujte adresu., id: ${this._id} link: ${this.link}`,
                  ),
                )
              }
            }

            next(new Error('Je podporováno pouze youtube nebo vimeo video.'))
          }
        },
    ],
  },
  statics: {
    // eslint-disable-next-line object-shorthand
    findByNanoid: function (nanoid) {
      return this.findOne({ nanoid })
    },
  },
}

// const urlsValidation = {
//   vimeo: yup.string().url().isVimeoUrl(),
//   youtube: yup.string().url().isYoutubeUrl(),
// }

// validationsss
export const validationSchemas = {
  // this is automatically added to presave hook
  base: generateYupForModel(model).shape({
    // you can extend it like this
    // description: yup.string().min(3, 'chyba'),
  }),
  // baseServer: generateYupForModel(model).shape({
  //   // you can extend it like this
  //   // description: yup.string().min(3, 'chyba'),
  //   videoType: null,
  // }),
  baseFrontend: generateYupForModel(model, { withoutHidden: true }).shape({}),
}
model.validationSchemas = validationSchemas

model.transformQuery = createTransformQuery(getTransformQueryOptions(model))
model.mapQueryToApiQueryParams = getMapQueryToApiQueryParams(model)

export default model
