import axios from 'axios'
import {
  createApiCall,
  createMutation,
  useQueryConfig,
  useConfiguredQuery,
  getParamsFromQueryKey,
  useConfiguredInfiniteQuery,
  memoizedCreateMutation,
} from 'lib/queries'
import isBrowser from 'lib/isBrowser'
import { createInfinitePrefetch, createPrefetch } from 'lib/rq'
import { useInfiniteQuery } from 'react-query'
import { useAuth } from 'lib/auth/useAuth'

const queryKey = 'messages'

// api calls
const fetchConnections = createApiCall({ path: 'messages' })
// const fetchOneConversation = createApiCall({ path: 'messages/:_id' })

// get all
export const useConnectionsQuery = (queryParams, config) =>
  useConfiguredQuery([queryKey, { queryParams }], fetchConnections, config)

// ----- MUTATIONS -------

export const useCreateMessageMutation = createMutation({
  queryKey,
  method: 'POST',
  path: '/messages',
})
