import { getOnlyFields } from './safe'

// for formik initial values generation
export const defaultFieldTypeInitialValues = {
  Text: '',
  Number: undefined,
  Email: '',
  Textarea: '',
  Boolean: false,
  Relationship: null,
  Select: null,
  Password: '',
  Url: '',
  TextArray: [],
}
export const resetFieldTypeInitialValues = {
  Text: '',
  Number: '',
  Email: '',
  Textarea: '',
  Boolean: false,
  Relationship: null,
  Select: null,
  Password: '',
  Url: '',
  TextArray: [],
}

export function getInitialValues(fields, { onlyFields } = {}) {
  onlyFields = getOnlyFields(onlyFields)
  const initialValues = {}

  Object.keys(fields)
    .filter(fieldKey => !onlyFields || onlyFields.includes(fieldKey))
    .filter(
      fieldKey =>
        !fields[fieldKey].hiddenFormik && !fields[fieldKey].hidden && !fields[fieldKey].hiddenApi,
    )
    .forEach(fieldKey => {
      const field = fields[fieldKey]
      const defaultValue = field.default || defaultFieldTypeInitialValues[field.type]
      initialValues[fieldKey] = defaultValue
    })

  return initialValues
}

export function getResetValues(fields, { onlyFields } = {}) {
  onlyFields = getOnlyFields(onlyFields)
  const resetValues = {}

  Object.keys(fields)
    .filter(fieldKey => !onlyFields || onlyFields.includes(fieldKey))
    .filter(
      fieldKey =>
        !fields[fieldKey].hiddenFormik && !fields[fieldKey].hidden && !fields[fieldKey].hiddenApi,
    )
    .forEach(fieldKey => {
      const field = fields[fieldKey]
      const resetValue = resetFieldTypeInitialValues[field.type]
      resetValues[fieldKey] = resetValue
    })

  return resetValues
}
