import { up } from 'lib/styles'
import React from 'react'
import styled, { css } from 'styled-components'

export const Page = styled.div`
  ${({ theme: { sizes, colors, pageSidePadding, mobilePageSidePadding }, bg }) => css`
    width: 100%;
    flex: 1 1 auto;
    /* min-height: 100%; */
    /* min-height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    /* you can define bg by multiple options */
    background: ${colors[bg] || bg};

    /* global page padding here! */
    padding: 0 ${mobilePageSidePadding};
    ${up('mobile')} {
      padding: 0 ${pageSidePadding};
    }
  `}
`

export const PageContent = styled.div`
  ${({ height, maxWidth, theme: { sizes }, position, align = 'stretch' }) => css`
    width: 100%;
    position: ${position};
    max-width: ${maxWidth || `${sizes.pageWidth}px`};
    display: flex;
    flex-direction: column;
    align-items: ${align};
  `}
`
