// the most used languages

import { createList, getValuesToLabels } from 'lib/selects'

export const languagesOptions = createList([
  'Angličtina',
  'Čeština',
  'Francouzština',
  'Italština',
  'Japonština',
  'Maďarština',
  'Němčina',
  'Polština',
  'Portugalština',
  'Ruština',
  'Slovenština',
  'Španělština',
  'Vietnamština',
  'Jiné',
])
export const languagesLabels = getValuesToLabels(languagesOptions)
