import { useEffect, useState } from 'react'

// returns true after initial didMount
// useful for ensuring that render is in browser and not SSR
export default function useIsMounted() {
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
  }, [])

  return mounted
}
