import * as yup from 'yup'
import { ObjectId } from 'bson'

class ObjectIdSchema extends yup.mixed {
  constructor() {
    super({ type: 'objectId' })

    this.withMutation(schema => {
      schema.transform(function (value) {
        if (this.isType(value)) return value
        return new ObjectId(value)
      })
    })
  }

  _typeCheck(value) {
    return ObjectId.isValid(value)
  }
}


export default function addObjectId(yup) {
  yup.objectId = () => new ObjectIdSchema()

  return yup
}


// Yup.addMethod(Yup.string, "creditCardLength", function (errorMessage) {
//   return this.test(`test-card-length`, errorMessage, function (value) {
//     const { path, createError } = this;

//     return (
//       (value && value.length === 16) ||
//       createError({ path, message: errorMessage })
//     );
//   });
// });