console.log('NODE_ENV: process.env.NODE_ENV', process.env.NEXT_PUBLIC_SERVER_ENV)

// shared by server and browser
export const bugsnagConfig = {
  appVersion: process.env.APP_VERSION,
  // no localhost, this is server env (eco). Full names are fallback if ENV goes wrong.
  enabledReleaseStages: ['prod', 'dev', 'production', 'development'],
  // enabledReleaseStages: ['prod', 'dev', 'production', 'development', 'local'],
  releaseStage: process.env.NEXT_PUBLIC_SERVER_ENV,
  metadata: {
    env: {
      NEXT_PUBLIC_SERVER_ENV: process.env.NEXT_PUBLIC_SERVER_ENV,
      NODE_ENV: process.env.NODE_ENV,
      IS_BROWSER: !!process.browser,
      NEXT_PUBLIC_APP_VERSION: process.env.NEXT_PUBLIC_APP_VERSION,
    },
  },
  redactedKeys: [
    'tokens',
    'email',
    /^password$/i, // case-insensitive: "password", "PASSWORD", "PaSsWoRd"
    'street',
    'city',
    'zipcode',
  ],
}
