// helpers for google tag manager / google analytics

import { useRouter } from 'next/router'
import { useEffect } from 'react'

// log the pageview with their URL
export const pageview = url => {
  window.gtag('config', process.env.NEXT_PUBLIC_GTAG, {
    page_path: url,
  })
}

// log specific events happening.
/**
 * example usage
 *  const search = () => {
    ga.event({
      action: "search",
      params : {
        search_term: query
      }
    })
  }
 */
export const event = ({ action, params }) => {
  window.gtag('event', action, params)
  // console.log('event', action, params)
}

// import this into _app.js
export const useGtag = () => {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = url => {
      pageview(url)
      // console.log('pageview')
    }
    // When the component is mounted, subscribe to router changes
    // and log those page views
    router.events.on('routeChangeComplete', handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])
}
