import { createList, getValuesToLabels } from '../selects'

export const regionOptions = createList([
  'Hlavní město Praha',
  'Středočeský kraj',
  'Jihočeský kraj',
  'Plzeňský kraj',
  'Karlovarský kraj',
  'Ústecký kraj',
  'Liberecký kraj',
  'Královéhradecký kraj',
  'Pardubický kraj',
  'Kraj Vysočina',
  'Jihomoravský kraj',
  'Olomoucký kraj',
  'Zlínský kraj',
  'Moravskoslezský kraj',
])

export const regionLabels = getValuesToLabels(regionOptions)

export const districtOptions = createList(['Hlavní město Praha', 'Praha-západ', 'Praha-východ'])

export const pragueQuarterOptions = createList([
  'Praha 1',
  'Praha 2',
  'Praha 3',
  'Praha 4',
  'Praha 5',
  'Praha 6',
  'Praha 7',
  'Praha 8',
  'Praha 9',
  'Praha 10',
])

export const cityPartOptions = createList([
  'Bohnice',
  'Běchovice',
  'Strašnice',
  'Vršovice',
  'Malešice',
  'Smíchov',
  'Dejvice',
  'Hostivař',
  'Záběhlice',
  'Žižkov',
  'Střešovice',
  'Vokovice',
  'Holešovice',
  'Karlín',
  'Vinohrady',
  'Michle',
])
