import { getParamsFromQueryKey } from './queries'

export const createPrefetch = (key, promise) => queryClient =>
  // eslint-disable-next-line arrow-body-style
  queryClient.prefetchQuery(key, ({ queryKey }) => {
    // console.log('SSR queryKey', queryKey)
    return promise(getParamsFromQueryKey(queryKey))
  })

export const createInfinitePrefetch = (key, promise) => queryClient =>
  // eslint-disable-next-line arrow-body-style
  queryClient.prefetchInfiniteQuery(key, ({ queryKey }) => {
    // console.log('SSR queryKey', queryKey)
    return promise(getParamsFromQueryKey(queryKey))
  })
