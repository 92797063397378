import crypto from 'crypto'
import jwt from 'jwt-simple'

export const randomHash = () => {
  const currentDate = new Date().valueOf().toString()
  const random = Math.random().toString()
  return crypto
    .createHash('sha1')
    .update(currentDate + random)
    .digest('hex')
}

export const createUserPreSaveHook = keystone =>
  async function (next) {
    // console.log('nextnext', next, this)
    if (this.oldPassword !== this.password) {
      // password was changed
      console.log('pass changed')

      this.oldPassword = this.password

      this.hashStamp = randomHash()
    }

    if (this.email) {
      this.email = this.email.toLowerCase()
    }

    next()
  }

export const decodeJWT = token => jwt.decode(token, process.env.JWT_SECRET)

//
export const processActivationData = async (data, User) => {
  const { email, activationCode } = data

  if (!email || !activationCode) {
    return { ok: false, nodata: true }
  }

  const user = await User.findOne({ email, activationCode })

  if (!user) {
    return { ok: false, nodata: true }
  }

  if (user.activated) {
    return { ok: false, alreadyActivated: true }
  }

  user.activated = true

  await user.save()

  return { ok: true, alreadyActivated: false }
}
