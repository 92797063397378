import { addResponsivity, up } from 'lib/styles'
import styled, { css } from 'styled-components'

const BetterText = styled.div`
  ${({
  theme: { colors },
  fontSize,
  mobileFontSize,
  lineHeight,
  mobileLineHeight,
  fontWeight,
  color,
  textAlign,
  mobileTextAlign,
  pointer,
  noWrap,
  maxWidth,
  withEllipsis,
  withShadow,
}) => css`
    margin: 0;
    padding: 0;
    font-size: ${mobileFontSize || fontSize};
    line-height: ${mobileLineHeight || lineHeight};
    font-weight: ${fontWeight};
    color: ${colors[color] || color || 'inherit'};
    text-align: ${mobileTextAlign || textAlign};
    max-width: ${maxWidth};

    ${pointer &&
    css`
      cursor: pointer;
    `}

    ${withShadow &&
    css`
      text-shadow: 0px 0px 20px black;
    `}

    ${withEllipsis &&
    css`
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    `}

    white-space: ${noWrap && 'nowrap'};

    ${up('mobile')} {
      text-align: ${textAlign};
    }

    ${addResponsivity()}
  `}
`

export default BetterText
