import Image from 'next/image'
import styled, { css } from 'styled-components'

const imageAttrs = () => ({
  quality: 90,
  width: 42,
  height: 42,
  objectFit: 'cover',
})

const AvatarImage = styled(Image).attrs(imageAttrs)`
  ${({ theme: { orbit } }) => css`
    border-radius: 1000px;
  `}
`
const AvatarImageWrapper = styled.div`
  flex-basis: 42px;
  width: 42px;
  height: 42px;
  flex-shrink: 0;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 1000px;
  overflow: hidden;
`

const getSrc = src =>
  src ? `${process.env.NEXT_PUBLIC_APP_URL}${src}` : '/images/avatar-placeholder.svg'

const BetterAvatar = ({ src, name, blurData }) => (
  <AvatarImageWrapper>
    <AvatarImage
      placeholder={!blurData ? false : 'blur'}
      blurDataURL={blurData}
      src={getSrc(src)}
      name={name}
      alt={name}
    />
  </AvatarImageWrapper>
)
export default BetterAvatar
