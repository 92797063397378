import * as yup from 'yup'
import addObjectId from './objectId'
import yupLocaleCs from './locale/cs'
import { addUrlValidations } from './urls'
import { addPhoneValidation } from './phone'

yup.setLocale(yupLocaleCs)
// console.log('setting locale for yup')

require('yup-password')(yup) // extend yup

addObjectId(yup)

addUrlValidations(yup)

addPhoneValidation(yup)

export default yup

export const passwordValidation = yup
  .string()
  .required()
  .password()
  .label('Heslo')
  .min(6, 'Musí být alespoň 6 znaků dlouhé')
  .minUppercase(1, 'Musí obsahovat alespoň 1 velké písmeno')
  .minNumbers(1, 'Musí obsahovat alespoň 1 číslici')
  .minSymbols(0, 'Musí obsahovat alespoň 1 speciální znak')
