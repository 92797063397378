import * as yup from 'yup'
import { FieldTypes, generateYupForModel } from 'lib/models/safe'
import { userChangePasswordSchema } from 'lib/auth/validate'
import { createUserPreSaveHook } from 'lib/auth/safe'
import { passwordValidation } from 'lib/yup'
import { nanoid } from 'nanoid'
import { createList, getValuesToLabels } from 'lib/selects'
import { regionOptions } from 'lib/options/addresses'
import { languagesOptions } from 'lib/options/languages'
import {
  createGetBrowserFilterQueryFunction,
  filterTypes,
  filterValueToBrowserUrlPresets,
  getMapQueryToApiQueryParams,
  getTransformQueryOptions,
} from 'lib/models/filters'
import { createTransformQuery } from 'lib/next'
import { slugifyUrl } from 'lib/slugify'

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()
const PNF = require('google-libphonenumber').PhoneNumberFormat

const roleOptions = [
  { label: 'USER', value: 'USER' },
  { label: 'ADMIN', value: 'ADMIN' },
]
export const videoTypesOptions = createList([
  'Svatební',
  'Firemní', // rozdil mezi firemnim a produktovym?
  'Produktová',
  'Hudební videoklipy',
  'Eventová',
  'Rozhovory', // mozna vyhodit -- stejne jako testimonial?
  'Testimonial', // mozna vyhodit
  'Showreel/Demoreel',
  'Realitní', // mozna vyhodit
  'Motion graphics', // spise text nez umelecky pocin
  'Animované', // nahradit za animovany explainer?
  // existuji nejaka jina anim. videa nez explainer?
  // animovane je vice umelecke
  'Ostatní',
  // Z dronu
])
export const videoTypesLabels = getValuesToLabels(videoTypesOptions)

export const skillsOptions = createList([
  'Kamera',
  'Střih',
  'Postprodukce',
  'Motion graphics',
  'Story',
  'Scénář',
  'Dramaturgie',
  'Režie',
  'Produkce',
  'Kompletní video',
])
export const skillsLabels = getValuesToLabels(skillsOptions)

export const linkKeys = [
  'vimeo',
  'youtube',
  'behance',
  'dribbble',
  'instagram',
  'facebook',
  'twitter',
  'web',
]

const budgetOptions = createList([
  '5 tisíc',
  '10 tisíc',
  '25 tisíc',
  '50 tisíc',
  '75 tisíc',
  '100 tisíc',
  '150 tisíc',
  '200 tisíc',
  '250 tisíc',
])

export const migrationSchemaVideoTypes = {
  internetovareklama: 'ostatni',
  kratkeonlinereklamy: 'ostatni',
  industry: 'firemni',
  youtube: 'ostatni',
  marketing: 'firemni',
  kratkaprosocialnisite: 'ostatni',
  hudebniklipy: 'hudebnivideoklipy',
}

export const migrationSchema = {
  budget: {
    vicejak150tis: '150tisic',
  },
  videoTypes: migrationSchemaVideoTypes,
}

export const isCompanyFilterOptions =
  // createList(['Jednotlivci', 'Týmy'])
  [
    {
      label: 'Týmy',
      value: true,
      slug: 'tymy',
    },
    {
      label: 'Jednotlivci',
      value: false,
      slug: 'jednotlivci',
    },
  ]

const isCreator = { isCreator: true }

const model = {
  name: 'User',
  resourceName: 'users',
  textSearchFields: ['name', 'email'],
  defaultColumns: 'name, email',
  defaultSort: '-updatedAt',
  nodelete: true,
  mapName: 'name',
  keepTrackFields: ['updatedAt'],
  fields: {
    // can be used as non deterministic id (mongoId is guessable)
    // e.g. for starting messages
    nanoid: {
      type: FieldTypes.Text,
      hiddenFormik: true,
      default: () => nanoid(5),
      index: true,
    },
    slug: {
      type: FieldTypes.Text,
      hiddenFormik: true,
    },
    name: {
      type: FieldTypes.Text,
      required: true,
      index: true,
      // minlength: [4],
      label: 'Jméno',
    },

    email: {
      type: FieldTypes.Email,
      required: true,
      index: true,
      hiddenFormik: true, // do not let user to change their profile
      label: 'Email',
    },
    phoneNumber: {
      // todo - validace a formáter telefonnu
      type: FieldTypes.Phone,
      label: 'Telefon',
      region: 'CZ',
    },
    role: {
      type: FieldTypes.Select,
      required: true,
      options: roleOptions,
      default: 'USER',
      hiddenFormik: true,
    },
    password: { type: FieldTypes.Password, hiddenApi: true },
    // TODO: move this to some lib?
    oldPassword: { type: FieldTypes.Text, noedit: true, hidden: true },
    hashStamp: { type: FieldTypes.Text, noedit: true, hiddenApi: true },
    activated: { type: FieldTypes.Boolean, noedit: true, hiddenApi: true },
    // number code to write in
    activationCode: { type: FieldTypes.Text, noedit: true, hiddenApi: true },
    activationCodeSentAt: { type: FieldTypes.Datetime, noedit: true, hiddenApi: true },

    profileImage: {
      label: 'Profilový obrázek',
      type: FieldTypes.Relationship,
      ref: 'UserImage',
    },
    // gallery: {
    //   label: 'Galerie',
    //   type: FieldTypes.Relationship,
    //   ref: 'UserImage',
    //   many: true,
    // },
    isCreator: {
      type: FieldTypes.Boolean,
      label: 'Jsem tvůrce',
      note: 'Zaškrtněte pokud jste tvůrce, budete zde prezentovat svou práci a chcete být osloveni novými zákazníky',
    },
    isCompany: {
      type: FieldTypes.Boolean,
      label: 'Jsme firma / tým lidí',
      note: 'Zaškrtněte pokud se prezentujete jako firma nebo skupina tvůrců a ne jako jednotlivec.',
      filterType: filterTypes.MULTISELECT,
      filterSlug: 'tvurci',
      filterOperand: 'OR',
      filterOptions: isCompanyFilterOptions,
      filterValueToBrowserUrl: filterValueToBrowserUrlPresets.array,
      default: false,
    },

    moneyFrom: {
      type: FieldTypes.Select,
      label: 'Minimální rozpočet na spolupráci',
      options: budgetOptions,
    },

    region: {
      type: FieldTypes.Select,
      label: 'Kraj',
      options: regionOptions,
      dependsOn: isCreator,
      requiredDependsOn: isCreator,
      filterType: filterTypes.MULTISELECT,
      filterSlug: 'lokalita',
      filterOperand: 'OR',
    },

    skills: {
      type: FieldTypes.TextArray,
      options: skillsOptions,
      label: 'Dovednosti',
      isMultiSelect: true,
      dependsOn: isCreator,
      requiredDependsOn: isCreator,
      filterType: filterTypes.MULTISELECT,
      filterSlug: 'dovednosti',
      filterOperand: 'AND',
    },
    videoTypes: {
      type: FieldTypes.TextArray,
      options: videoTypesOptions,
      label: 'Typy videí',
      isMultiSelect: true,
      dependsOn: isCreator,
      requiredDependsOn: isCreator,
      filterType: filterTypes.MULTISELECT,
      filterSlug: 'videa',
      filterOperand: 'OR',
    },
    description: {
      type: FieldTypes.Textarea,
      label: 'Moje představení',
      dependsOn: isCreator,
      placeholder: 'Proč by si mě měl zadavatel vybrat...',
      requiredDependsOn: isCreator,
    },
    languages: {
      type: FieldTypes.TextArray,
      options: languagesOptions,
      label: 'Jazyky',
      isMultiSelect: true,
      dependsOn: isCreator,
      requiredDependsOn: isCreator,
    },
    vimeo: {
      type: FieldTypes.Url,
      label: 'Vimeo',
      dependsOn: isCreator,
    },
    youtube: {
      type: FieldTypes.Url,
      label: 'YouTube',
      dependsOn: isCreator,
    },
    behance: {
      type: FieldTypes.Url,
      label: 'Behance',
      dependsOn: isCreator,
    },
    dribbble: {
      type: FieldTypes.Url,
      label: 'Dribbble',
      dependsOn: isCreator,
    },
    instagram: {
      type: FieldTypes.Url,
      label: 'Instagram',
      dependsOn: isCreator,
    },
    facebook: {
      type: FieldTypes.Url,
      label: 'Facebook',
      dependsOn: isCreator,
    },
    twitter: {
      type: FieldTypes.Url,
      label: 'Twitter',
      dependsOn: isCreator,
    },
    web: {
      type: FieldTypes.Url,
      label: 'Web',
      dependsOn: isCreator,
    },
    lastLoginAt: {
      type: FieldTypes.Datetime,
      hiddenApi: true,
    },
    loginsAt: {
      type: FieldTypes.TextArray,
      hiddenApi: true,
    },

    // job: {
    //   type: FieldTypes.Text,
    //   dependsOn: isCreator,
    //   label: 'Povolání',
    // },
  },
  relationships: [{ path: 'videos', ref: 'Video', refPath: 'author' }],

  hooks: {
    preSave: [
      createUserPreSaveHook,
      keystone =>
        async function (next) {
          console.log('hook pre save user', this.phoneNumber)
          if (this.phoneNumber) {
            const number = phoneUtil.parseAndKeepRawInput(this.phoneNumber, 'CZ')
            if (phoneUtil.isValidNumber(number)) {
              console.log(phoneUtil.format(number, PNF.NATIONAL))
              this.phoneNumber = phoneUtil.format(number, PNF.NATIONAL)
            }
          }

          // regenerate user slug
          this.slug = `${this.nanoid}-${slugifyUrl(this.name)}`

          next()
        },
    ],
  },
  statics: {
    // eslint-disable-next-line object-shorthand
    findByNanoid: function (nanoid) {
      return this.findOne({ nanoid })
    },
    // eslint-disable-next-line object-shorthand
    findBySlug: function (slug) {
      return this.findOne({ slug })
    },
  },
}

const urlsValidation = {
  vimeo: yup.string().url().isVimeoUrl(),
  youtube: yup.string().url().isYoutubeUrl(),
  behance: yup.string().url().isBehanceUrl(),
  dribbble: yup.string().url().isDribbbleUrl(),
  instagram: yup.string().url().isInstagramUrl(),
  facebook: yup.string().url().isFacebookUrl(),
  twitter: yup.string().url().isTwitterUrl(),
}

// validations
export const validationSchemas = {
  // this is automatically added to presave hook
  base: generateYupForModel(model).shape({
    // you can extend it like this
    // description: yup.string().min(3, 'chyba'),
    ...urlsValidation,
  }),
  baseFrontend: generateYupForModel(model, { withoutHidden: true }).shape({
    ...urlsValidation,
  }),
  // generate validations only for some fields
  registerStep1: generateYupForModel(model, { onlyFields: 'name, password, email' }),
}
model.validationSchemas = validationSchemas

model.transformQuery = createTransformQuery(getTransformQueryOptions(model))
model.mapQueryToApiQueryParams = getMapQueryToApiQueryParams(model)

export default model
