import Box from '@kiwicom/orbit-components/lib/Box'
import Loading from '@kiwicom/orbit-components/lib/Loading'
import Stack from '@kiwicom/orbit-components/lib/Stack'
import Gap from 'lib/components/Gap'
import React from 'react'
import { links } from 'src/links'
import styled, { css } from 'styled-components'
import SEO from 'src/components/SEO'
import Alert from './Alert'
import BetterLink from './BetterLink'

const Container = styled.div`
  ${({}) => css`
    padding: 100px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  `}
`

const Wrapper = styled.div`
  ${({ maxWidth }) => css`
    max-width: ${maxWidth};
  `}
`

function PageLoader({ maxWidth, seoTitle }) {
  return (
    <Container>
      <SEO title={seoTitle} />

      <Wrapper maxWidth={maxWidth}>
        <Loading loading text="Chvilku strpení, váš zážitek se připravuje..." type="pageLoader" />
      </Wrapper>
    </Container>
  )
}

PageLoader.Error = ({
  children,
  maxWidth = '500px',
  afterAlert,
  seoTitle,
  title,
  withoutLink,
  ...props
}) => (
  <Container>
    <SEO title={seoTitle || title} />

    <Wrapper maxWidth={maxWidth}>
      <Alert.Critical title={title} {...props}>
        {children || (
          <>
            Při načítání stránky nastala chyba. Zkuste to znovu. Pokud problém přetrvává, obraťte se
            na naší podporu. Děkujeme...
          </>
        )}
      </Alert.Critical>

      <Gap gap="24px" />

      {afterAlert}

      {!afterAlert && !withoutLink &&
        <BetterLink href={links.HOMEPAGE}>Jít na úvodní stránku</BetterLink>}
    </Wrapper>
  </Container>
)

PageLoader.Info = ({
  children,
  maxWidth = '500px',
  afterAlert,
  seoTitle,
  title,
  withoutLink,
  ...props
}) => (
  <Container>
    <SEO title={seoTitle || title} />

    <Wrapper maxWidth={maxWidth}>
      <Alert.Info title={title} {...props}>
        {children}
      </Alert.Info>

      <Gap gap="24px" />

      {afterAlert}

      {!afterAlert && !withoutLink &&
        <BetterLink href={links.HOMEPAGE}>Jít na úvodní stránku</BetterLink>}
    </Wrapper>
  </Container>
)

export default PageLoader
