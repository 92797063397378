export function defaultOnError(event) {
  // const appState = require('./main').getState()

  // const app = {
  //   ...appState.app,
  // }
  // const user = {
  //   ...app.viewer,
  // }
  // delete app.user

  // TODO: pridat usera a stav appky pokud chceme
  // event.setUser(user._id, user.username, user.name)
  // event.addMetadata('user', {
  //   ...user,
  // })
  // event.addMetadata('state', {
  //   // if needed, add sth from state, but check limit (1 MB)
  //   config: appState.config,
  //   app: appState.app,
  // })

  try {
    const viewport = {
      width: window.innerWidth || document.documentElement.clientWidth,
      height: window.innerHeight || document.documentElement.clientHeight,

      layout: {
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
      },
    }
    viewport.zoom = viewport.layout.width / viewport.width

    event.addMetadata('device', {
      cookieEnabled: window.navigator.cookieEnabled,
      ...viewport,
    })
  } catch (error) {
    console.log('inaccessible device data', error)
  }

  try {
    // add issue id, and display it to user for better issue lookup
    // const issueId = nanoid()
    // event.addMetadata('issue', {
    //   issueId,
    // })
    // window.issueId = issueId
  } catch (error) {
    console.log('issue id not generated', error)
  }

  // if (process.env.NODE_ENV !== 'production') {
  //   // too big dev console statements (limit is 1 MB)
  //   console.log('deleting breadcrumbs')
  //   delete event.breadcrumbs
  // }
}
