import { ButtonLink, Drawer, Heading, TextLink } from '@kiwicom/orbit-components'
import Button from '@kiwicom/orbit-components/lib/Button'
import LinkList from '@kiwicom/orbit-components/lib/LinkList'
import NavigationBar from '@kiwicom/orbit-components/lib/NavigationBar'
import Stack from '@kiwicom/orbit-components/lib/Stack'
import { useAuth } from 'lib/auth/useAuth'
import Col from 'lib/components/Col'
import Gap from 'lib/components/Gap'
import Row from 'lib/components/Row'
import useIsMounted from 'lib/hooks/isMounted'
import { up } from 'lib/styles'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { links } from 'src/links'
import styled from 'styled-components'
import BetterAvatar from './BetterAvatar'
import BetterText from './BetterText'
import Icon from './Icon'
// import logoPath from '../../public/images/logo.svg'

const logoBase64 =
  'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTciIGhlaWdodD0iNDIiIHZpZXdCb3g9IjAgMCA1NyA0MiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzg4MjFfNzUxOSkiPgo8cmVjdCB4PSIxNC4xNzM4IiB5PSIxNC41NTc0IiB3aWR0aD0iMjYuNTE4NyIgaGVpZ2h0PSIyNi42ODAzIiBzdHJva2U9IiMxQzFGMUMiIHN0cm9rZS13aWR0aD0iMS41MjQwNiIvPgo8cGF0aCBkPSJNMjcuMjgwNyAxNC43MTU1QzI3LjI4MDcgMjIuMDg3MiAyMS4zNDAyIDI4LjA1NTYgMTQuMDIxNCAyOC4wNTU2QzYuNzAyNTcgMjguMDU1NiAwLjc2MjAzMiAyMi4wODcyIDAuNzYyMDMyIDE0LjcxNTVDMC43NjIwMzIgNy4zNDM3NyA2LjcwMjU3IDEuMzc1MzEgMTQuMDIxNCAxLjM3NTMxQzIxLjM0MDIgMS4zNzUzMSAyNy4yODA3IDcuMzQzNzcgMjcuMjgwNyAxNC43MTU1WiIgc3Ryb2tlPSIjMUMxRjFDIiBzdHJva2Utd2lkdGg9IjEuNTI0MDYiLz4KPHBhdGggZD0iTTU3IDE0LjcxNTNMMzEuODUyOSAyOS4zMTc2TDMxLjg1MjkgMC4xMTI5OUw1NyAxNC43MTUzWiIgZmlsbD0iIzJBNEJGRiIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzg4MjFfNzUxOSI+CjxyZWN0IHdpZHRoPSI1NyIgaGVpZ2h0PSI0MiIgZmlsbD0id2hpdGUiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K'

const MenuLinkWrapper = styled.div`
  padding: 10px;
`

const MenuLink = ({ children, href }) => {
  const router = useRouter()

  const isActive = router.pathname === href

  const type = isActive ? 'primary' : 'secondary'

  return (
    <Col
      alignItems="center"
      alignSelf="center"
      justifyContent="center"
      height="100%"
      showAfter={815}
    >
      <Link href={href} passHref>
        <TextLink type={type}>
          <MenuLinkWrapper>{children}</MenuLinkWrapper>
        </TextLink>
      </Link>
    </Col>
  )
}

const MenuButtonLink = ({ children, href, type }) => (
  <Col alignItems="center" alignSelf="center" justifyContent="center" height="100%" showAfter={815}>
    <Link href={href} passHref>
      <Button type={type}>{children}</Button>
    </Link>
  </Col>
)

const MenuTextLink = ({ children, href, onClick, type: defaultType = 'secondary' }) => {
  const router = useRouter()

  const isActive = router.pathname === href
  // TODO: isActive nefunguje pro href={{...object}}

  const type = isActive ? 'primary' : defaultType

  return (
    <Link href={href} passHref>
      <TextLink type={type} onClick={onClick}>
        {children}
      </TextLink>
    </Link>
  )
}
const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  img {
    height: 38px;
    ${up(768)} {
      height: 42px;
    }
  }
`

function NavBar(props) {
  const router = useRouter()
  const { user, mountedUser, signOut } = useAuth()
  const isMounted = useIsMounted()

  const [menuOpen, setMenuOpen] = useState(false)

  const getIsActive = route => router.pathname === route

  const onDrawerClick = () => {
    setMenuOpen(false)
  }

  const commonDrawerProps = {
    onClick: onDrawerClick,
  }

  return (
    <>
      <Drawer
        shown={menuOpen}
        onClose={() => {
          setMenuOpen(false)
        }}
      >
        <LinkList spacing="large">
          <Heading type="title5">Účet</Heading>
          {isMounted && (
            <>
              {mountedUser && (
                <LinkList spacing="large">
                  {mountedUser.isCreator && (
                    <MenuTextLink
                      href={{
                        pathname: links.CREATOR_PUBLIC_DETAIL,
                        query: { slug: mountedUser.slug },
                      }}
                      {...commonDrawerProps}
                    >
                      Můj veřejný profil
                    </MenuTextLink>
                  )}
                  <MenuTextLink href={links.PROFILE} {...commonDrawerProps}>
                    Nastavení profilu
                  </MenuTextLink>
                  <MenuTextLink href={links.SIGN_OUT} {...commonDrawerProps} type="critical">
                    Odhlásit se
                  </MenuTextLink>
                </LinkList>
              )}

              {!mountedUser && (
                <LinkList spacing="large">
                  <MenuTextLink href={links.AUTH} {...commonDrawerProps}>
                    Přihlásit se
                  </MenuTextLink>

                  <MenuTextLink href={links.AUTH_REGISTER} {...commonDrawerProps}>
                    Registrovat
                  </MenuTextLink>
                </LinkList>
              )}
            </>
          )}

          <Gap />

          <Heading type="title5">Portál</Heading>
          <MenuTextLink href={links.HOMEPAGE} {...commonDrawerProps}>
            Úvodní stránka
          </MenuTextLink>

          <MenuTextLink {...commonDrawerProps} href={links.CREATORS}>
            Seznam tvůrců
          </MenuTextLink>

          <MenuTextLink {...commonDrawerProps} href={links.VIDEOS}>
            Referenční videa
          </MenuTextLink>

          <MenuTextLink {...commonDrawerProps} href={links.FAQ}>
            Časté otázky
          </MenuTextLink>

          <MenuTextLink {...commonDrawerProps} href={links.BLOG}>
            Blog
          </MenuTextLink>
          <Gap gap="0" />
        </LinkList>
      </Drawer>
      <NavigationBar
        onMenuOpen={() => {
          setMenuOpen(true)
        }}
        onHide={() => {
          setMenuOpen(false)
        }}
      >
        <Stack direction="row" align="center" justify="between" spacing="none">
          <LinkList direction="row" spacing="XXXSmall">
            <Stack direction="row" spacing="none" align="center">
              <Link href="/" passHref>
                <LogoWrapper>
                  <img src={logoBase64} width="57" height="42" alt="" />
                </LogoWrapper>
              </Link>
            </Stack>

            <Gap gap="8px" />
            {/* <MenuLink type="white" href={links.CREATORS}>
              Seznam tvůrců
            </MenuLink> */}
            <MenuLink type="white" href={links.CREATORS}>
              Videotvůrci
            </MenuLink>
            <MenuLink type="white" href={links.VIDEOS}>
              Referenční videa
            </MenuLink>
            <MenuLink type="white" href={links.FAQ}>
              Časté otázky
            </MenuLink>
            <MenuLink type="white" href={links.BLOG}>
              Blog
            </MenuLink>
          </LinkList>
          <Stack inline direction="row" spacing="normal" align="center">
            {isMounted && (
              <>
                {mountedUser && (
                  <Link href={links.PROFILE}>
                    <Row alignItems="center">
                      <BetterText pointer desktop>
                        {user.name}
                      </BetterText>
                      <Gap />
                      <BetterAvatar
                        src={user.profileImage?.filePath}
                        name={user.name}
                        blurData={user.profileImage?.blurData}
                      />
                    </Row>
                  </Link>
                )}
                {!mountedUser && (
                  <MenuButtonLink type="primarySubtle" href={links.AUTH}>
                    Přihlásit se
                  </MenuButtonLink>
                )}
                {!mountedUser && <Gap />}
                {!mountedUser &&
                  <MenuButtonLink href={links.AUTH_REGISTER}>Registrovat</MenuButtonLink>}
              </>
            )}
          </Stack>
        </Stack>
      </NavigationBar>
    </>
  )
}

export default NavBar
