import 'yup-phone'

export const addPhoneValidation = yup => {
  yup.addMethod(
    yup.string,
    'validatePhone',
    function (region = 'CZ', strict = true, errorMessage = 'Zadejte správný telefon') {
      const phoneSchema = yup.string().phone(region, strict, errorMessage)
      return this.test('test-phone', errorMessage, value => {
        if (value) return phoneSchema.isValidSync(value)
        return true
      })
    },
  )
}
