import OrbitAlert from '@kiwicom/orbit-components/lib/Alert'
import { useState } from 'react'
import { extractErrorMessages } from 'lib/queries'
import Col from 'lib/components/Col'
import { useFormikContext } from 'formik'
import { useModelContext } from './fields/ModelFormik'

const Alert = ({ children, ...props }) => (
  <OrbitAlert icon {...props}>
    {children}
  </OrbitAlert>
)

// TODO: micro copy
Alert.Success = ({ ...props }) => <Alert title="Úspěch" type="success" {...props} />

Alert.Critical = ({ ...props }) => <Alert title="Chyba" type="critical" {...props} />

Alert.Warning = ({ ...props }) => <Alert title="Pozor" type="warning" {...props} />

Alert.Info = ({ ...props }) => <Alert title="Upozornění" type="info" {...props} />

// only shows on error and auto generate messages
Alert.MutationError = ({ mutation, children, ...props }) => {
  const errorMessages = extractErrorMessages(mutation.error).map(msg => <div>{msg}</div>)

  return (
    mutation.error && (
      <Alert.Critical {...props}>
        <Col>
          {typeof children === 'function'
            ? children({ errorMessages, errors: mutation.error?.errors })
            : children || errorMessages}
        </Col>
      </Alert.Critical>
    )
  )
}
// only shows on success
Alert.MutationSuccess = ({ mutation, children, ...props }) =>
  mutation.isSuccess && <Alert.Success {...props}>{children}</Alert.Success>

const ModelFormikError = () => {
  const { model } = useModelContext()
  const { errors, touched } = useFormikContext()

  // console.log('errors', errors, touched)

  const touchedErrors = Object.keys(errors).reduce((acc, key) => {
    if (touched[key]) {
      return { ...acc, [key]: errors[key] }
    }
    return acc
  }, {})

  return (
    Object.keys(touchedErrors)?.length > 0 && (
      <Alert.Critical title="Formulář obsahuje chyby">
        <ul>
          {Object.keys(touchedErrors).map(fieldKey => (
            <li key={fieldKey}>
              {model.fields[fieldKey].label}: {touchedErrors[fieldKey]}
            </li>
          ))}
        </ul>
      </Alert.Critical>
    )
  )
}
Alert.ModelFormikError = ModelFormikError

export default Alert
