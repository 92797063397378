import { createGlobalStyle, css } from 'styled-components'
import { getTokens } from '@kiwicom/orbit-components'
// import { getFontsCss } from 'lib/styles/fonts'
import { theme as baseTheme } from 'lib/styles'
import chroma from 'chroma-js'
import styledNormalize from 'styled-normalize'
// import { fonts } from './fonts'

// this causes refetching font on dynamic css. it has to be loaded independently
// const fontsCss = css`
//   ${getFontsCss({ fonts })}
// `

const baseColors = {
  primary: '#2B4BFE',
  primaryLight: '#D7DDFF',

  // old realito colors
  blueLight: '#D1DAFC',
  textDark: '#252A31',
  textMedium: '#5F738C', // only chat -- remove?
}

const productNormal = chroma(baseColors.primary)
const productLight = chroma(baseColors.primaryLight)
// const productLight = productNormal.brighten(2).desaturate(0.5)
const productDark = productNormal.darken(1.2)

// customize orbit here
const tokens = getTokens({
  palette: {
    product: {
      light: productLight.hex(),
      lightHover: productLight.darken(0.3).hex(),
      lightActive: productLight.darken(0.4).hex(),
      normal: productNormal.hex(),
      normalHover: productNormal.darken(0.4).hex(),
      normalActive: productNormal.darken(0.5).hex(),
      dark: productDark.hex(),
      darkHover: productDark.darken(0.4).hex(),
      darkActive: productDark.darken(0.5).hex(),
      darker: productDark.darken(1).hex(),
    },
  },
})

// console.log('tokens', tokens)

// https://orbit.kiwi/foundation/color/color-blocks/
// https://orbit.kiwi/design-tokens/
const colors = {
  ...baseColors,
  cloudLight: tokens.paletteCloudLight,
  formLabel: tokens.colorFormLabel,
  formLabelFilled: tokens.colorFormLabelFilled,
  redNormal: tokens.paletteRedNormal,
  socialIcon: tokens.colorIconPrimary,
  socialIconHover: tokens.paletteProductNormal,
  primary: tokens.paletteProductNormal,
  productNormal: tokens.paletteProductNormal,
  iconPrimary: tokens.colorIconPrimary,
  textSecondary: tokens.colorTextSecondary,
}

export const GlobalStyle = createGlobalStyle`
  ${styledNormalize}
/* ${fontsCss} -- this causes refetching font on dynamic css. it has to be loaded independently*/
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  html, body {
    margin: 0;
    padding: 0;

    font-family: Roboto, sans-serif;
    width: 100%;
    height: 100%;

    background: ${colors.cloudLight};
  }

  #__next {
    width: 100%;
    height: 100%;
  }
`

// console.log('tokens',tokens )

export const theme = {
  ...baseTheme,
  colors,
  orbit: tokens,
  transitions: true, // enable transitions for orbit

  pageSidePadding: '24px',
  mobilePageSidePadding: '16px',
  sizes: {
    pageWidth: 1280, // default page width
  },
  sizesPx: {
    // same sizes as above, but in pixel string
    pageWidth: '1280px',
  },
}
