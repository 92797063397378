import React from 'react'
import styled, { css } from 'styled-components'
import { up, addResponsivity } from 'lib/styles'

const Col = styled.div`
  ${({
  theme: { colors },
  justifyContent,
  mobileJustifyContent,
  align,
  alignItems,
  alignSelf,
  mobileAlignSelf,
  mobileAlign,
  mobileAlignItems,
  position,
  maxWidth,
  mobileMaxWidth,
  minWidth,
  width,
  height,
  mobileWidth,
  flex,
  grow,
  basis,
  mobileBasis,
  shrink,
  padding,
  margin,
  mobilePadding,
  mobileMargin,
  bp = 'mobile',
  ignoreMinContent,
  direction = 'column',
  mobileDirection = 'column',
}) => css`
    display: flex;
    flex-direction: ${mobileDirection || direction};
    min-width: ${ignoreMinContent && 0};
    align-items: ${mobileAlign || mobileAlignItems || align || alignItems};
    justify-content: ${mobileJustifyContent || justifyContent};
    max-width: ${mobileMaxWidth || maxWidth};
    min-width: ${minWidth};
    width: ${mobileWidth || width};
    height: ${height};
    position: ${position};
    flex: ${flex};
    align-self: ${mobileAlignSelf || alignSelf};
    flex-grow: ${grow};
    flex-shrink: ${shrink};
    padding: ${mobilePadding || padding};
    margin: ${mobileMargin || margin};
    flex-basis: ${mobileBasis || basis};

    ${up(bp)} {
      padding: ${padding};
      margin: ${margin};
      align-self: ${alignSelf};
      max-width: ${maxWidth};
      align-items: ${align || alignItems};
      justify-content: ${justifyContent};
      width: ${width};
      flex-direction: ${direction};
      flex-basis: ${basis};
    }

    ${addResponsivity({ displayAs: 'flex' })}
  `}
`

export default Col

export const BasicPageContentCol = props =>
  <Col maxWidth="720px" width="100%" alignItems="flex-start" alignSelf="center" {...props} />
