import React from 'react'
import styled from 'styled-components'
import BetterLink, { PureLink } from './BetterLink'
import Icon from './Icon'

const Wrapper = styled.div`
  background: #128c7e;
  position: fixed;
  bottom: 16px;
  right: 16px;
  color: white;
  padding: 12px;
  border-radius: 200px;
  box-shadow: 0px 2px 15px rgb(0 0 0 / 30%);
`

function WAButton(props) {
  return (
    <PureLink target="_blank" rel="noopener" href="https://wa.me/420736711117">
      <Wrapper>
        <Icon.Whatsapp />
      </Wrapper>
    </PureLink>
  )
}

export default WAButton
