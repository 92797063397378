const urls = {
  isVimeoUrl: {
    label: 'Vimeo',
    url: 'vimeo.com',
  },
  isYoutubeUrl: {
    label: 'YouTube',
    url: 'youtube.com',
  },
  isBehanceUrl: {
    label: 'Behance',
    url: 'behance.com',
  },
  isDribbbleUrl: {
    label: 'Dribbble',
    url: 'dribbble.com',
  },
  isInstagramUrl: {
    label: 'Instagram',
    url: 'instagram.com',
  },
  isFacebookUrl: {
    label: 'Facebook',
    url: 'facebook.com',
  },
  isTwitterUrl: {
    label: 'Twitter',
    url: 'twitter.com',
  },
}

export const addUrlValidations = yup => {
  Object.keys(urls).forEach(key => {
    const conf = urls[key]
    yup.addMethod(yup.string, key, function (errorMessage = `Není platná ${conf.label} url`) {
      return this.test(`test-${key}`, errorMessage, function (value) {
        const { path, createError } = this

        // return (
        //   /https:\/\/vimeo.com\/\d{8}(?=\b|\/)/.test(value) ||
        //   createError({ path, message: errorMessage })
        // )
        return (
          !value ||
          new RegExp(`https://(www.)?${conf.url}/`).test(value) ||
          createError({ path, message: errorMessage })
        )
      })
    })
  })

  // yup.addMethod(yup.string, 'isVimeoUrl', function (errorMessage = 'Není platná Vimeo url') {
  //   return this.test('test-isVimeoUrl', errorMessage, function (value) {
  //     const { path, createError } = this

  //     // return (
  //     //   /https:\/\/vimeo.com\/\d{8}(?=\b|\/)/.test(value) ||
  //     //   createError({ path, message: errorMessage })
  //     // )
  //     return (
  //       !value ||
  //       /https:\/\/vimeo.com\//.test(value) ||
  //       createError({ path, message: errorMessage })
  //     )
  //   })
  // })
}
