import { FieldTypes, getValues, getOptionsMap } from 'lib/models/safe'

const model = {
  name: 'Connection',
  resourceName: 'connections',
  // textSearchFields: ['title', 'body', 'author'],
  // toStringFields: ['title', 'body', 'author'],
  defaultColumns: 'owner, applicant, offer, state, isFavorite',
  defaultSort: '-updatedAt',
  nodelete: true,
  mapName: 'title',

  fields: {
    title: {
      noedit: true,
      type: FieldTypes.Text,
      note: 'internal use only',
      hiddenApi: true, // from api response AND formik
    },
    creator: {
      type: FieldTypes.Relationship,
      ref: 'User',
    },
    customer: {
      type: FieldTypes.Relationship,
      ref: 'User',
    },
  },

  // hooks: {
  //   preSave: [
  //     keystone =>
  //       async function (next) {
  //         // TODO: populate owner / applicant / offer
  //         this.title = this.state
  //         next()
  //       },
  //   ],
  // },
}

export default model
