import { Button, Dialog } from '@kiwicom/orbit-components'
import React, { useState } from 'react'

function BetterDialog({
  children,
  title = 'Jste si jisti?',
  onConfirm,
  onCancel,
  description,
  confirmButtonText = 'OK',
  cancelButtonText = 'Storno',
  confirmButtonType = 'primary',
}) {
  const [isDialogShown, setIsDialogShown] = useState(false)

  return (
    <>
      {children({ openDialog: () => setIsDialogShown(true) })}

      {isDialogShown && (
        <Dialog
          description={description}
          title={title}
          primaryAction={(
            <Button
              type={confirmButtonType}
              onClick={async () => {
                try {
                  onConfirm?.()
                  setIsDialogShown(false)
                } catch (error) {
                  console.log('error', error)
                  alert('Akce se nepovedla, opakujte prosím')
                }
              }}
            >
              {confirmButtonText}
            </Button>
          )}
          secondaryAction={(
            <Button
              type="secondary"
              onClick={() => {
                onCancel?.()
                setIsDialogShown(false)
              }}
            >
              {cancelButtonText}
            </Button>
          )}
          onClose={(...args) => {
            onCancel?.()
            setIsDialogShown(false)
          }}
        />
      )}
    </>
  )
}

BetterDialog.ConfirmDelete = ({ ...props }) => (
  <BetterDialog
    description="Neuložené změny budou ztraceny"
    confirmButtonType="critical"
    confirmButtonText="Zahodit změny"
    cancelButtonText="Vrátit se"
    {...props}
  />
)

BetterDialog.ConfirmLeave = ({ ...props }) => (
  <BetterDialog
    description="Neuložené změny budou ztraceny"
    confirmButtonType="critical"
    confirmButtonText="Odejít"
    cancelButtonText="Vrátit se"
    {...props}
  />
)

export default BetterDialog
