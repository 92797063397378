import { FieldTypes, generateYupForModel } from 'lib/models/safe'
import { areMongoIdsSame } from 'lib/mongo'
import striptags from 'striptags'

const model = {
  name: 'Message',
  resourceName: 'messages',
  // textSearchFields: ['title', 'body', 'author'],
  // toStringFields: ['title', 'body', 'author'],
  defaultColumns: 'receiver, sender, email, phone, name, createdAt',
  defaultSort: '-createdAt',
  nodelete: true,
  noedit: true,
  mapName: 'message',
  keepTrackFields: ['createdAt'],

  fields: {
    message: {
      type: FieldTypes.Textarea,
      required: true,
      label: 'Vaše zpráva',
    },
    name: {
      type: FieldTypes.Text,
      required: true,
      label: 'Jméno',
    },
    email: {
      type: FieldTypes.Email,
      required: true,
      label: 'E-mail',
    },
    phone: {
      type: FieldTypes.Phone,
      required: true,
      label: 'Telefon',
    },

    sender: {
      type: FieldTypes.Relationship,
      ref: 'User',
      // required: true,
      // not required - can be created by user without login!
      hiddenFormik: true,
      index: true,
    },
    receiver: {
      type: FieldTypes.Relationship,
      ref: 'User',
      required: true,
      index: true,
      // hiddenFormik: true,
    },
    emailSent: {
      type: FieldTypes.Boolean,
    },
  },
}

// validations
export const validationSchemas = {
  // this is automatically added to presave hook
  base: generateYupForModel(model),
}
model.validationSchemas = validationSchemas

export default model
