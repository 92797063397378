import axios, { CancelToken } from 'axios'
import qs from 'qs'
import memoize from 'fast-memoize'

import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query'
import isBrowser from 'lib/isBrowser'
import { createContext, useContext } from 'react'
import { getInMemoryTokens, useAuth } from './auth/useAuth'

const firstLetterToLowerCase = s => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toLowerCase() + s.slice(1)
}

class ApiError extends Error {
  constructor(originalError) {
    super(originalError.message)
    this.errors = originalError?.response?.data?.errors?.map?.(e => ({
      ...e,
      namedMessage: `${e.path || e.label ? `${e.label || e.path} ` : ''}${
        e.path || e.label ? firstLetterToLowerCase(e.message) : e.message
      }`,
    }))
    this.name = 'ApiError'
  }
}

export const extractErrorMessages = error => error?.errors?.map(error => error.namedMessage) || []

const queryConfigContext = createContext({ initial: true })

export const createQueryConfigProvider = () => {}

export const createHeaderFromTokens = tokens => {
  const headers = {}

  if (tokens?.access) {
    headers['x-access-token'] = tokens.access
  }

  return headers
}

// provide url for redirect if token is expired
// provide fn that returns {tokens: { access }} for secured api headers
// export const QueryConfigProvider = ({ onTokenExpired, children, useTokensContext }) => {
//   const { tokens } = useAuth()

//   const headers = {}

//   if (tokens?.access) {
//     headers['x-access-token'] = tokens.access
//   }

//   console.log('QueryConfigProvider', tokens)

//   const providedState = {
//     onTokenExpired,
//     tokens,
//     headers,
//     test: 'asdf',
//   }

//   return <queryConfigContext.Provider value={providedState}>{children}</queryConfigContext.Provider>
// }

// export const useQueryConfig = () => useContext(queryConfigContext)

// const apiProviders = {}

// // creates memoized apiProvider to create apiCalls
// export const createApiProvider = ({ key }) => {
//   if (apiProviders[key]) {
//     return apiProviders[key]
//   }

//   apiProviders[key] = {
//     createApiCall
//   }

// }

export const createApiCall = ({
  method = 'get',
  path,
  data: body,
  params = {},
  queryParams,
  headers,
  tokens,
  enablePagination,
}) => {
  const base = isBrowser ? process.env.NEXT_PUBLIC_APP_URL : `http://localhost:${process.env.PORT}`

  const source = CancelToken.source()

  // throws error
  // this is exposed
  const apiCall = async ({
    data,
    path: runtimePath,
    params: runtimeParams,
    _id,
    queryParams: runtimeQueryParams,
    headers: runtimeHeaders,
    tokens: runtimeTokens,
    pageParam, // pagination injected from RQ
    startPage,
  } = {}) => {
    const page = pageParam || (enablePagination ? startPage : undefined)
    // console.log('pageParampage', pageParam, page, enablePagination)

    let finalPath = runtimePath || path

    const finalParams = runtimeParams || params
    if (_id) {
      finalParams._id = _id
    }
    Object.keys(finalParams).forEach(paramKey => {
      if (finalParams[paramKey]) {
        finalPath = finalPath.replace(`:${paramKey}`, finalParams[paramKey])
      }
    })

    const finalQueryParams = { page, ...queryParams, ...runtimeQueryParams }

    // console.log('finalPath', finalPath, finalParams, _id)

    const finalTokens = runtimeTokens || tokens

    const finalHeaders = { ...headers, ...runtimeHeaders, ...createHeaderFromTokens(finalTokens) }
    // console.log('AXIOS - headers when trigger', finalHeaders, finalPath)

    try {
      const response = await axios({
        method,
        baseURL: `${base}/api/v1/`,
        url: finalPath,
        data: data || body,
        headers: finalHeaders,
        params: finalQueryParams,
        paramsSerializer: qs.stringify,
        cancelToken: source.token,
      })
      if (!enablePagination) {
        // console.log('response', response.headers)
        // this is our thing (response has code, data, status, meta...)
        return response?.data?.data
      }
      // with pagination we want to expose all meta data
      return response?.data
    } catch (error) {
      console.log('axios error', error.response, error.response?.data?.errors)
      throw new ApiError(error)
    }
  }

  // Cancel the request if React Query calls the `promise.cancel` method
  apiCall.cancel = () => {
    source.cancel('Query was cancelled by React Query')
  }

  return apiCall
}

export function handleTokenError(error, onTokenExpired) {
  if (
    error?.errors?.some(error => error.name === 'TokenIsExpired' || error.name === 'RestrictedArea')
  ) {
    onTokenExpired()
  }
}

export const createMutation =
  ({ queryKey, method, path, invalidateQueries }) =>
    ({ queryParams, params, ...config } = {}) => {
    // this is exposed as useHook^^

      const queryClient = useQueryClient()

      const { onTokenExpired, tokens } = useAuth()

      return useMutation(
        data => {
          console.log('data, runtimeApiConfig', data)

          const { injectToApi, ...rest } = data || {}

          return createApiCall({
            path,
            method,
            data: rest,
            params,
            queryParams,
            onTokenExpired,
            tokens,
            ...injectToApi,
          })()
        },
        {
          ...config,
          onSuccess: responseData => {
          // invalidate cached data (with all subkeys)
            if (queryKey) {
              queryClient.invalidateQueries(queryKey)
            }
            if (invalidateQueries) {
              queryClient.invalidateQueries(invalidateQueries)
            }
            config?.onSuccess?.(responseData)
          },
          onError: error => {
            config?.onError?.(error)
            handleTokenError(error, onTokenExpired)
          },
        },
      )
    }

export const memoizedCreateMutation = memoize(createMutation)

export function useConfiguredQuery(key, asyncCall, config) {
  const { onTokenExpired, tokens } = useAuth()

  // console.log('useConfiguredQuery', tokens)

  // const headers = createHeaderFromTokens(tokens)

  return useQuery(
    key,
    ({ queryKey }) => asyncCall({ tokens, onTokenExpired, ...getParamsFromQueryKey(queryKey) }),
    config,
  )
}

export function getParamsFromQueryKey(queryKey) {
  const result = {}

  // console.log('queryKey',queryKey )
  queryKey.forEach(element => {
    if (element?.queryParams) {
      result.queryParams = element.queryParams
    }
    if (element?.params) {
      result.params = element.params
    }
    if (element?.startPage) {
      result.startPage = element.startPage
    }
  })
  // console.log('result', result)
  return result
}

export function useConfiguredInfiniteQuery(key, asyncCall, config) {
  const { onTokenExpired, tokens } = useAuth()
  // console.log('useConfiguredQuery', tokens)
  // const headers = createHeaderFromTokens(tokens)
  return {
    queryKey: key,
    ...useInfiniteQuery(
      key,
      ({ queryKey, pageParam }) =>
        asyncCall({ tokens, onTokenExpired, ...getParamsFromQueryKey(queryKey), pageParam }),
      {
        getNextPageParam: (lastPage, pages) => lastPage?.meta?.nextPage,
        getPreviousPageParam: (lastPage, pages) => lastPage?.meta?.previousPage,
        ...config,
      },
    ),
  }
}
