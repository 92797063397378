import React, {
  Component,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { Field, Form, Formik } from 'formik'

import { getInitialValues, getResetValues } from 'lib/models/formik'
import { allModels } from 'models'
import { nanoid } from 'nanoid'

const modelContext = createContext({})

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useModelContext = () => useContext(modelContext)

const ModelFormik = ({
  validationSchemaKey,
  modelName,
  mutation,
  onSuccess,
  resetOnSuccess,
  mergeInitialValues,
  disableAll,
  onDidMount,
  commonProps, // Just idea - inject props to all components via context
  ...props
}) => {
  const model = useMemo(() => allModels.find(model => model.name === modelName), [modelName])

  // can be used without model
  // if (!model) {
  //   return <div>Model not found - {modelName}</div>
  // }

  const schema = model?.validationSchemas?.[validationSchemaKey]

  const handleSubmit = async (data, actions) => {
    // console.log('formik sumbit ', data)
    try {
      if (mutation) {
        await mutation.mutateAsync(data)
      }

      onSuccess?.(data, actions)

      if (resetOnSuccess) {
        actions.resetForm()
      }
    } catch (error) {
      // console.log('error async mutate', error, error.data, error.message, error.errors, error.error)
      const formikErrors = {}
      error.errors?.forEach(e => {
        if (e.type === 'ValidationError' && e.path) {
          formikErrors[e.path] = e.message
        }
      })
      actions.setErrors(formikErrors)
    }
    actions.setSubmitting(false)
  }

  let initialValues = { ...props.initialValues, ...mergeInitialValues }

  if (model) {
    initialValues = {
      ...getInitialValues(model.fields),
      ...mergeInitialValues,
    }
  }

  // console.log('disableAlldisableAll',disableAll )

  useEffect(() => {
    onDidMount?.(initialValues)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <modelContext.Provider
      value={{
        modelName,
        model,
        disableAll,
      }}
    >
      <Formik
        // automatically generate default values
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
        {...props}
      />
    </modelContext.Provider>
  )
}

export default ModelFormik
