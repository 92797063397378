// server variant use directly in API router

import React from 'react'
import BugsnagCore from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPluginExpress from '@bugsnag/plugin-express'
import { defaultOnError } from './defaultOnError'
import { applyMetadata } from './applyMetadata'
import { bugsnagConfig } from './config'

// console.log(
//   'process.env.SERVER_BUGSNAG_KEY || process.env.NEXT_PUBLIC_APP_BUGSNAG_KEY',
//   process.env.SERVER_BUGSNAG_KEY,
//   process.env.NEXT_PUBLIC_APP_BUGSNAG_KEY,
// )

// console.log('process.browser', process.browser, typeof window !== 'undefined')

const plugins = [new BugsnagPluginReact(React)]

if (!process.browser) {
  plugins.push(BugsnagPluginExpress)
}

BugsnagCore.start({
  ...bugsnagConfig,
  apiKey: process.env.SERVER_BUGSNAG_KEY || process.env.NEXT_PUBLIC_APP_BUGSNAG_KEY,
  plugins,
  onError: defaultOnError,

})

// if needed, we can implement manipulation of original metadata
export const notifyInfo = (err, customMetadata, metadata = {}) =>
  Bugsnag.notify(err, event => {
    defaultOnError(event)
    event.severity = 'info'
    applyMetadata(event, customMetadata, metadata)
  })

export const notifyWarning = (err, customMetadata, metadata = {}) =>
  Bugsnag.notify(err, event => {
    defaultOnError(event)
    event.severity = 'warning'
    applyMetadata(event, customMetadata, metadata)
  })

export const notifyError = (err, customMetadata, metadata = {}) =>
  Bugsnag.notify(err, event => {
    defaultOnError(event)
    applyMetadata(event, customMetadata, metadata)
  })

// propagate to console
console.notifyInfo = notifyInfo
console.notifyWarning = notifyWarning
console.notifyError = notifyError

export default BugsnagCore

export const Bugsnag = BugsnagCore
